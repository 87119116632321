import { InfoCircleOutlined, RightOutlined } from '@ant-design/icons';
import React from 'react';
import ButtonPrimary from '../customAntD/ButtonPrimary';

const addTagsButton = (
  <ButtonPrimary
    className='no-padding'
    size='middle'
    type='link'
    icon={<RightOutlined />}
    onClick={() => {
      window.location.href = '/clients-management/activate-clients';
    }}
  >
    Add tags
  </ButtonPrimary>
);

const DashboardFilterEmpty = ({ filter }: { filter: string }) => (
  <div className='dashboard__filters__empty'>
    {filter === 'industry' && (
      <>
        <InfoCircleOutlined className='dashboard__filters__empty__icon' />
        <>
          <h5>No Industry Data Imported</h5>
          <p>Please make sure the clients' industries are specified in their Xero files' Line of Business settings.</p>
        </>
      </>
    )}
    {filter === 'tags' && (
      <>
        <p> No clients tagged - {addTagsButton} </p>
      </>
    )}
  </div>
);

export default DashboardFilterEmpty;
