import React from 'react';
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';

const TrendArrow = ({ changePercentage }) => {
  if (!changePercentage) {
    return null;
  }

  return changePercentage < 0 ? (
    <ArrowDownOutlined />
  ) : (
    <ArrowUpOutlined />
  );
};

export default TrendArrow;
