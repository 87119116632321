import React, { useEffect, useState } from 'react';
import { Form, Skeleton } from 'antd';
import { observer } from 'mobx-react';
import { PracticeTypes } from '@aider/constants-library';
import Markdown from 'markdown-to-jsx';
import draftToHtml from 'draftjs-to-html';
import _ from 'lodash';
import { useStore } from '../../../stores/Store';
import WysiwygEditor from '../WysiwygEditor';
import AiderAlert from '../../AiderAlert';
import handleError from '../../../lib/errorHandler';
import { customEntityTransform } from '../../../lib/componentHelpers/reportHelpers';
import { LodashTemplateVariablePattern } from '../../../models/constants/components';

const TextComponent = ({
  className,
  block,
}: {
  className: string,
  block: PracticeTypes.ReportBlock,
}) => {
  const { insightStore, reportTemplateStore } = useStore();
  const isEditing = reportTemplateStore.editBlock === block.id;
  const { insightsLoading } = insightStore;
  const { fetchingVariables } = reportTemplateStore;

  const [loading, setLoading] = useState(false);

  const savedPrompts = reportTemplateStore.selectedPerformanceReport?.settings[block.id]?.prompts;

  useEffect(() => {
    setLoading(true);

    if (block.content) {
      setLoading(false);
    }
  }, [block.content]);

  const blockHasContent = () => {
    if (block.content && (block.content?.blocks.length > 1 || block.content?.blocks[0].text !== '')) {
      return true;
    }

    if (savedPrompts && savedPrompts.length > 0) {
      return true;
    }

    return false;
  };

  const returnAppropriateComponent = () => {
    const variablePattern = LodashTemplateVariablePattern;
    if (isEditing) {
      if (fetchingVariables || insightsLoading || (loading && blockHasContent())) {
        return <Skeleton active title={false} paragraph={{ rows: 2 }} />;
      }

      return (
        <>
          <WysiwygEditor
            blockType={block.type}
            content={block?.content}
            savedPrompts={savedPrompts}
            variables={reportTemplateStore?.flatSortedTemplateVariables}
            onChange={(content) => { // eslint-disable-line @typescript-eslint/no-unused-vars
              // This does nothing other than run the action which allows
              // the form to be updated with the new content. It requires
              // the new value to be passed in, but we don't need to do anything with it.
            }}
          />
        </>
      );
    }

    // we have a block with content
    if (blockHasContent()) {
      if (!insightsLoading && !fetchingVariables && !loading) {
        const notHaveFallbackVariables = Object.keys(reportTemplateStore.fallbackFormattedVariables).length === 0;
        // This check was giving undefined formatted values as making this check invalid. Added a nil check in formatting function instead of only checking null.
        const allSortedVariablesInFallbackVariables = Object.values(reportTemplateStore.flatSortedTemplateVariables)
          .every((v) => {
            const fallback = reportTemplateStore.fallbackFormattedVariables?.[v?.value?.replace('{', '').replace('}', '')];
            return !_.isNil(fallback);
          });

        // Debugging for missing variables
        if (!allSortedVariablesInFallbackVariables && process.env.NODE_ENV === 'development') {
          console.debug('Missing Variables:', ...Object.values(reportTemplateStore.flatSortedTemplateVariables).filter((v) => {
            const fallback = reportTemplateStore.fallbackFormattedVariables?.[v?.value?.replace('{', '').replace('}', '')];
            return _.isNil(fallback);
          }));
        }

        if (notHaveFallbackVariables || !allSortedVariablesInFallbackVariables || insightsLoading || loading) {
          return <Skeleton active title={false} paragraph={{ rows: 2 }} />;
        }

        try {
          return (
            <Markdown className={`${className}--${block.type}--content`}>
              {_.template(draftToHtml(block.content, null, null, customEntityTransform), { interpolate: variablePattern })(reportTemplateStore.fallbackFormattedVariables)}
            </Markdown>
          );
        } catch (e: any) {
          handleError({ error: e });
          return (
            <AiderAlert type='error' title='There was an issue with this text block please reinsert all variables and retry.' />
          );
        }
      }
      return <Skeleton active title={false} paragraph={{ rows: 2 }} />;
    }

    return (
      <AiderAlert
        title={`Empty ${block.type === 'header' ? 'title' : 'text'} block`}
      />
    );
  };

  return (
    <Form.Item
      className={`${className} ${className}--${isEditing ? 'editing' : 'read-only'} ${className}--${block.type}`}
      name={block.id}
      label={null}
      initialValue={block.content}
    >
      {returnAppropriateComponent()}
    </Form.Item>
  );
};

export default observer(TextComponent);
