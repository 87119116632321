import React, { ReactNode } from 'react';
import { Button } from 'antd';
import { trackMixpanelEvent } from '../../lib/mixpanel';
import { useStore } from '../../stores/Store';

const ButtonSecondary = ({
  className,
  children,
  onClick,
  mixpanelEvent,
  mixpanelProperties,
  ...rest
}: {
  block?: boolean;
  danger?: boolean;
  disabled?: boolean;
  ghost?: boolean;
  href?: string;
  htmlType?: 'button' | 'submit' | 'reset';
  icon?: ReactNode;
  loading?: boolean | { delay: number };
  shape?: 'default' | 'circle' | 'round';
  size?: 'large' | 'middle' | 'small';
  target?: string;
  type?: 'text' | 'link' | 'dashed' | 'primary' | 'default';
  onClick?: (event: React.MouseEvent) => void;
  mixpanelEvent?: string;
  mixpanelProperties?: any;
  className?: string;
  children?: ReactNode;
  style?: any;
  [key: string]: any;
}) => {
  const rootStore = useStore();
  const clickHandler = async (event: React.MouseEvent) => {
    event.stopPropagation();

    // This is to interact with the auto logout feature, as the
    // stop propagation bypasses the event listener in TimeoutProvider
    // but we want to keep the same functionality
    rootStore.createTimeout();

    await trackMixpanelEvent({
      description: mixpanelEvent,
      rootStore,
      properties: mixpanelProperties,
    });
    return onClick(event);
  };

  return (
    <Button
      className={`button-secondary ${className}`}
      onClick={clickHandler}
      {...rest}
    >
      {children}
    </Button>
  );
};

ButtonSecondary.defaultProps = {
  className: '',
  block: false,
  danger: false,
  disabled: false,
  ghost: false,
  href: undefined,
  htmlType: undefined,
  icon: undefined,
  loading: false,
  shape: 'round',
  size: 'middle',
  target: undefined,
  type: 'default',
  mixpanelEvent: 'Button Clicked',
  mixpanelProperties: null,
  onClick: () => {},
  children: undefined,
  style: {},
};

export default ButtonSecondary;
