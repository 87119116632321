import React from 'react';
import { CaretDownFilled } from '@ant-design/icons';
import { observer } from 'mobx-react';
import { TreeSelect } from 'antd';
import { ReportQuickContent } from '@aider/constants-library/dist/constants/report';
import { useStore } from '../../stores/Store';
import { trackMixpanelEvent } from '../../lib/mixpanel';

const WysiwygQuickContent = () => {
  const rootStore = useStore();
  const { reportTemplateStore, localeStore, insightStore } = rootStore;

  const handleSelect = (insightTitle: string) => {
    trackMixpanelEvent({ description: 'Report Template Editor - Insert Quick Content', properties: { quickContentKey: insightTitle }, rootStore });
    reportTemplateStore.injectTrendText(insightTitle);
  };

  const quickContentsChildren = (insight: string) => {
    const { availableBudgets } = insightStore;
    const children = [{
      title: 'vs Historical',
      value: `${insight}_historical`,
      key: `${insight}_historical`
    }];

    availableBudgets.forEach((budget, index) => {
      children.push({
        title: `vs Budget '${budget?.label}'`,
        value: `${insight}_budget${index}`,
        key: `${insight}_budget${index}`
      });
    });

    return children;
  };

  const treeData = Object.keys(ReportQuickContent).map((key) => ({
    title: localeStore.translation(`report-template-editor.titles.${key}`),
    value: `quick-content-${key}`,
    key: `quick-content-${key}`,
    selectable: false,
    children: ReportQuickContent[key].map((insight: string) => (
      {
        title: localeStore.translation(`report-template-editor.${key === 'profitability' ? 'titles' : 'variables'}.${insight}`),
        value: `${insight}`,
        key: `${insight}`,
        selectable: key !== 'profitability',
        children: key === 'profitability' ? quickContentsChildren(insight) : undefined
      }
    ))
  }));

  return (
    <TreeSelect
      showSearch
      value={null}
      className='wysiwyg-custom-dropdown wysiwyg-custom-dropdown__variables'
      popupClassName='ant-tree-dropdown'
      treeData={treeData}
      treeExpandAction='click'
      placeholder='Insert Quick Content'
      onChange={handleSelect}
      suffixIcon={<CaretDownFilled />}
    />
  );
};

export default observer(WysiwygQuickContent);
