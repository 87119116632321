import * as React from 'react';
import { Tooltip } from 'antd';
import { AiderEarlyWarning, EarlyWarning } from '../../../components-v2/atoms/Icons';

const EarlyWarningHeaderIcon = () => {
  const tooltipContents = (
    <div className='custom-tooltip'>
      <p>
        <EarlyWarning scale={0.8} style={{ marginBottom: '0.2em' }} /> Aider Early Warning System
      </p>
      <span>
        Indicative trends, assuming historical patterns continue.
        If potential issues are indicated, investigate further using
        related insights or talk to your client about doing full analysis.
      </span>
    </div>
  );

  return (
    <>
      <Tooltip placement='bottom' title={tooltipContents}>
        <AiderEarlyWarning style={{ marginBottom: '0.2em', marginLeft: '0.5rem' }} data-tip={tooltipContents} />
      </Tooltip>
    </>
  );
};

export default EarlyWarningHeaderIcon;
