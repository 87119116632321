import React, { useEffect } from 'react';
import { Form, Select } from 'antd';
import { observer } from 'mobx-react';
import { CountryConstants, ReportEnums } from '@aider/constants-library';
import { useStore } from '../../stores/Store';
import OverwriteChangesModal from '../modals/OverwriteChangesModal';
import { trackMixpanelEvent } from '../../lib/mixpanel';

const ReportTemplateSelector = ({ className = '' }: { className?: string }) => {
  const rootStore = useStore();
  const { businessesStore, reportTemplateStore, practiceStore } = rootStore;
  const [form] = Form.useForm();

  const templateOptions = reportTemplateStore?.reportTemplateSelectionList;

  // We need to set the correct default template based on the country
  useEffect(() => {
    if (
      businessesStore.selectedBusinessConfig?.overrides?.defaultReportTemplate
    ) {
      reportTemplateStore.selectedPerformanceReportTemplate =
        businessesStore.selectedBusinessConfig.overrides.defaultReportTemplate;
    } else if (
      !reportTemplateStore.isTemplateEdited
      && !CountryConstants.CountriesWithSalesTax.includes(
        practiceStore.countryCode
      )
      && templateOptions.findIndex(
        (template) => template.value === ReportEnums.ReportPredefinedTemplates.defaultUS
      ) !== -1
      && reportTemplateStore.selectedPerformanceReportTemplate
        === ReportEnums.ReportPredefinedTemplates.default
    ) {
      reportTemplateStore.selectedPerformanceReportTemplate =
        ReportEnums.ReportPredefinedTemplates.defaultUS;
    }
  }, []);

  useEffect(() => {
    if (
      businessesStore.selectedBusinessConfig?.overrides?.defaultReportTemplate
    ) {
      reportTemplateStore.selectedPerformanceReportTemplate =
        businessesStore.selectedBusinessConfig.overrides.defaultReportTemplate;
    }
  }, [businessesStore.selectedBusinessId]);

  useEffect(() => {
    if (reportTemplateStore.selectedPerformanceReportTemplate) {
      form.setFieldsValue({
        reportTemplate: reportTemplateStore.selectedPerformanceReportTemplate,
      });
    }
  }, [reportTemplateStore.selectedPerformanceReportTemplate]);

  const switchTemplate = (value) => {
    trackMixpanelEvent({
      description: 'Report Editor - Change Template',
      properties: { reportTemplate: value },
      rootStore,
    });
    reportTemplateStore.editedPerformanceReportTemplate = null;
    reportTemplateStore.selectedPerformanceReportTemplate = value;
  };

  const onReportTemplateSelection = ({ reportTemplate }) => {
    if (!reportTemplate) return;
    if (reportTemplateStore.isTemplateEdited) {
      trackMixpanelEvent({
        description: 'Report Editor - Change Template Confirmation',
        properties: { reportTemplate },
        rootStore,
      });
      OverwriteChangesModal(
        () => {
          trackMixpanelEvent({
            description: 'Report Editor - Discard Changes',
            properties: { reportTemplate },
            rootStore,
          });
          switchTemplate(reportTemplate);
        },
        () => {
          trackMixpanelEvent({
            description: 'Report Editor - Stay on Template',
            properties: { reportTemplate },
            rootStore,
          });
          form.setFieldsValue({
            reportTemplate:
              reportTemplateStore.selectedPerformanceReportTemplate,
          });
        }
      );
    } else {
      switchTemplate(reportTemplate);
    }
  };

  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  return (
    <Form
      form={form}
      onValuesChange={onReportTemplateSelection}
    >
      <Form.Item
        name='reportTemplate'
        label={null}
        initialValue={reportTemplateStore.selectedPerformanceReportTemplate}
      >
        <Select
          showSearch
          filterOption={filterOption}
          className={className}
          size='large'
          options={templateOptions}
          onChange={(value) => {
            trackMixpanelEvent({
              description: 'Report Editor - Template Selected',
              properties: {
                templateId: value,
                templateName: templateOptions.find(
                  (template) => template.value === value
                )?.label,
              },
              rootStore,
            });
          }}
        />
      </Form.Item>
    </Form>
  );
};

ReportTemplateSelector.defaultProps = {
  className: '',
};

export default observer(ReportTemplateSelector);
