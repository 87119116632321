import React, { useState, useMemo } from 'react';
import { Form, TreeSelect, Select } from 'antd';
import { observer } from 'mobx-react';
import { CheckCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import { convertFromRaw } from 'draft-js';
import { PracticeTypes, SplitEnums } from '@aider/constants-library';
import { useStore } from '../../../stores/Store';
import { InsightTab } from '../../../stores/v1/pageStore';
import ButtonPrimary from '../../customAntD/ButtonPrimary';
import AiderAlert from '../../AiderAlert';
import { sortReportInsightTree, formatTableData } from '../../../lib/componentHelpers/reportHelpers';
import { AntDesignTreeData } from '../../../models/interfaces/antDesignElements';
import usePrevious from '../../../lib/customHooks/usePrevious';

interface ReportBlockSettingsContentProps {
  type: 'chart' | 'table';
  closeModal: () => void;
}

const ReportBlockSettingsContent = ({ type, closeModal }: ReportBlockSettingsContentProps) => {
  const { localeStore, reportTemplateStore, businessesStore, insightStore, splitStore } = useStore();
  const graphBlock = reportTemplateStore.editedPerformanceReportTemplate?.blocks.find((b) => b.id === reportTemplateStore.editBlock);
  const initialValue = graphBlock?.content ? convertFromRaw(graphBlock?.content).getPlainText() : undefined;
  const [value, setValue] = useState<string>(initialValue);
  const prevValue = usePrevious(value);
  const [datapoints, setDatapoints] = useState([]);
  const [localSettings, setLocalSettings] = useState<PracticeTypes.ReportSettingsObject>({});
  const [hasExistingSettings, setHasExistingSettings] = useState<boolean>(false);
  const filteredInsightData = reportTemplateStore.filteredInsightGraphData;
  const label = type === 'chart' ? 'Graph' : 'Table';

  const data: AntDesignTreeData[] = useMemo(() => filteredInsightData.reduce((acc, insight) => {
    let categoryInx;
    let category;
    const categoryArray = [InsightTab.profitability, InsightTab.cashflow, InsightTab.taxAndCompliance];
    const categoryTitles = {
      [InsightTab.profitability]: 'Profitability',
      [InsightTab.cashflow]: 'Cash flow',
      [InsightTab.taxAndCompliance]: 'Tax & Compliance',
    };

    const categoryId = insightStore.getInsightCategory(insight.insightKey);
    if (categoryArray.includes(categoryId as InsightTab)) {
      categoryInx = acc.findIndex((cat) => cat.key === categoryId);
      category = categoryInx === -1
        ? {
          value: categoryId,
          key: categoryId,
          title: categoryTitles[categoryId],
          selectable: false,
          children: []
        }
        : acc[categoryInx];
      category.children.push({
        value: insight.insightKey,
        key: insight.insightKey,
        title: localeStore.translation(`insights.${insight.insightKey}.title`)
      });

      if (splitStore.featureEnabled(SplitEnums.FeatureFlags.Budget) && insight?.budgets?.length) {
        insight.budgets.forEach((budget, ix) => {
          let budgetTitle = `${localeStore.translation(`insights.${insight.insightKey}.title`)}`;
          budgetTitle += ` vs. ${budget.budgetName}`;
          category.children.push({
            value: `${insight.insightKey}-budget-${ix}`,
            key: `${insight.insightKey}-budget-${ix}`,
            title: budgetTitle,
          });
        });
      }
    }
    if (categoryInx === -1) {
      acc.push(category);
    } else {
      acc[categoryInx] = category;
    }
    return sortReportInsightTree(acc);
  }, []), [filteredInsightData]);

  React.useEffect(() => {
    if (label === 'Graph') return;
    let selectedInsight;
    if (value && value?.indexOf('budget') !== -1) {
      const [baseInsight, , budgetIndex] = value?.split('-');
      selectedInsight = filteredInsightData.find((insight) => insight.insightKey === baseInsight)?.budgets[budgetIndex];
    } else {
      selectedInsight = filteredInsightData.find((insight) => insight.insightKey === value);
    }

    if (selectedInsight) {
      const columns = formatTableData(
        businessesStore.selectedBusiness,
        businessesStore.selectedBusinessFinancialYearEnd,
        selectedInsight.graph,
        reportTemplateStore.selectedPerformanceReport?.settings?.[reportTemplateStore.editBlock],
        reportTemplateStore.selectedPeriodData?.granularity,
        value,
        localeStore,
        false
      ).columns.slice(1).map((col) => ({ value: col.key, label: col.title }));
      setDatapoints(columns);
    }

    const { settings } = reportTemplateStore.selectedPerformanceReport;

    if (settings && prevValue && prevValue !== value) {
      setHasExistingSettings(true);
    } else {
      setHasExistingSettings(false);
    }
  }, [value]);

  React.useEffect(() => {
    if (datapoints.length) {
      const storedSelection = reportTemplateStore.selectedPerformanceReport?.settings?.[reportTemplateStore.editBlock]?.selectedItems;
      const defaultSelection = datapoints.map((dp) => dp.value);
      setLocalSettings({
        selectedItems: !hasExistingSettings && storedSelection ? storedSelection : defaultSelection,
      });
    }
  }, [datapoints]);

  const onChange = (newValue: string) => {
    setValue(newValue);
  };

  const onDataPointSelectChange = (newSelectedDataPoints) => {
    setLocalSettings({
      ...localSettings,
      selectedItems: newSelectedDataPoints,
    });
  };

  return (
    <div className='add-insight-graph-modal'>
      <section className='add-insight-graph-modal__body'>
        {data.length === 0 ? (
          <AiderAlert
            type='warning'
            title={`No ${type} data available for selected period`}
          />
        ) : (
          <Form layout='vertical'>
            <Form.Item label={`${label} Data`}>
              <TreeSelect
                value={value}
                className='ant-tree-select'
                popupClassName='ant-tree-dropdown'
                treeExpandedKeys={[InsightTab.profitability, InsightTab.cashflow, InsightTab.taxAndCompliance]}
                treeData={data}
                placeholder={`Select ${label} Data`}
                onChange={onChange}
              />
            </Form.Item>
            {
              label === 'Table' && (
                <Form.Item label='Select Columns'>
                  <Select
                    mode='multiple'
                    className='ant-tree-select'
                    popupClassName='ant-tree-dropdown'
                    options={datapoints}
                    placeholder={`Select ${label} Point data`}
                    allowClear
                    value={localSettings.selectedItems}
                    onChange={onDataPointSelectChange}
                  />
                </Form.Item>
              )
            }
          </Form>
        )}
      </section>
      <section className='add-insight-graph-modal__footer'>
        {data.length > 0 && (
          <ButtonPrimary
            mixpanelEvent='Report Editor - Save Block Setings'
            mixpanelProperties={{ type, value }}
            icon={<CheckCircleOutlined />}
            onClick={() => {
              reportTemplateStore.insertChartPointer(value, localSettings.selectedItems);
              reportTemplateStore.saveReportSettings(reportTemplateStore.editBlock, localSettings);
              closeModal();
            }}
            disabled={!value}
          >
            OK
          </ButtonPrimary>
        )}
        {reportTemplateStore.editBlock && (
          <ButtonPrimary
            mixpanelEvent='Report Editor - Delete Block'
            mixpanelProperties={{ type }}
            icon={<DeleteOutlined />}
            danger
            onClick={() => {
              reportTemplateStore.deleteBlock(reportTemplateStore.editBlock);
              closeModal();
            }}
          >
            Delete
          </ButtonPrimary>
        )}
      </section>
    </div>
  );
};

export default observer(ReportBlockSettingsContent);
