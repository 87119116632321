import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { AccountEnums } from '@aider/constants-library';
import { RightOutlined } from '@ant-design/icons';
import { useStore } from '../../stores/Store';
import { ClientSettingsPages } from '../../models/enums/modals';
import { InsightRelevantSettings } from '../../models/enums/components';
import ClientSettingsModal from '../modals/ClientSettingsModal';
import ButtonPrimary from '../customAntD/ButtonPrimary';

type Props = {
  insightKey: string,
}

const InsightSettings = ({ insightKey }: Props) => {
  const rootStore = useStore();
  const { localeStore } = rootStore;
  const settingType = InsightRelevantSettings?.[insightKey] || 'None';

  useEffect(() => {
    if (settingType === 'bankAccounts') {
      rootStore.businessesStore.fetchBankAccounts(
        rootStore.businessesStore.selectedBusinessId
      );
    }
  }, [settingType]);

  const getSettingsText = () => {
    const businessConfig = rootStore.businessesStore?.selectedBusinessConfig;
    switch (settingType) {
      case 'incomeTax': {
        const clientTaxRate = rootStore.businessesStore?.selectedBusiness?.profile?.taxRate;
        const defaultTaxRate = rootStore.businessesStore?.defaultTaxRate;
        const accountCount = businessConfig?.overrides?.incomeTaxAccounts?.length;
        const taxRate = !clientTaxRate || clientTaxRate === '' ? defaultTaxRate : clientTaxRate;

        return localeStore.translation(
          `insights.settingsText.insightTax.${accountCount ? 'hasAccounts' : 'noAccounts'}`,
          { taxRate, accountCount }
        );
      }
      case 'taxAgentExtension': {
        return localeStore.translation(
          `insights.settingsText.taxAgentExtension.${rootStore.businessesStore?.selectedBusiness?.profile?.taxAgent ? 'true' : 'false'}`
        );
      }
      case 'profitability': {
        const accountCount = businessConfig?.overrides?.[AccountEnums.InsightToAccountSettingMap[insightKey]]?.length;
        if (['netProfit', 'grossProfit'].includes(insightKey)) {
          return localeStore.translation(
            `insights.settingsText.profitability.${insightKey}`
          );
        }
        return localeStore.translation(
          `insights.settingsText.profitability.default.${accountCount ? 'hasAccounts' : 'noAccounts'}`,
          { accountCount }
        );
      }
      case 'bankAccounts':
        if (rootStore.businessesStore.selectedBusinessBankAccounts && rootStore.businessesStore.selectedBusinessBankAccounts.length > 0) {
          const selectedNumber = rootStore.businessesStore?.selectedBusiness.profile?.hasSelectedBankAccount?.length
            ? rootStore.businessesStore?.selectedBusiness?.profile?.hasSelectedBankAccount?.length
            : 'all';
          return localeStore.translation(
            'insights.settingsText.insightBankAccounts',
            { selectedNumber, accountCount: rootStore.businessesStore.selectedBusinessBankAccounts.length }
          );
        }
        return localeStore.translation('insights.settingsText.default');
      default:
        return localeStore.translation('insights.settingsText.default');
    }
  };

  return (
    <section className='insight-container__details--settings'>
      <h4>
        Settings
      </h4>
      <p>
        {getSettingsText()}
        <ButtonPrimary
          type='link'
          size='small'
          className='no-padding'
          mixpanelEvent='Insight Settings - Change'
          mixpanelProperties={{ insightKey, settingType }}
          onClick={() => {
            ClientSettingsModal(rootStore, ClientSettingsPages.CLIENT_CONFIGURATION);
          }}
          icon={<RightOutlined />}
        >
          Change
        </ButtonPrimary>
      </p>
    </section>
  );
};

export default observer(InsightSettings);
