import { PracticeTypes, ReportConstants, ReportEnums } from '@aider/constants-library';
import { DateTime } from 'luxon';
import { ColumnType } from 'antd/es/table';
import Format, { ValueTypes } from '@aider/aider-formatting-library';
import { EditorState } from 'draft-js';
import { AntDesignTreeData } from '../../models/interfaces/antDesignElements';
import LocaleStore from '../../stores/localeStore';

/**
  * Takes the string and index of two items and returns the sort value
  * able to be used as the return value for the sort function
  * If both indexes are -1, sort alphabetically
  * If one index is -1, sort that one last
  * If neither index is -1, sort by index
  *
  * @param {string} aText - The text of the first item
  * @param {number} aIndex - The index of the first item
  * @param {string} bText - The text of the second item
  * @param {number} bIndex - The index of the second item
  * @returns {number} - The sort value
  */
export const getAlphabeticalFallbackBubbleSortValue = (
  aText: string,
  aIndex: number,
  bText: string,
  bIndex: number
): number => {
  if (aIndex === -1 && bIndex === -1) {
    return aText.localeCompare(bText);
  }

  if (aIndex === -1) {
    return 1;
  }

  if (bIndex === -1) {
    return -1;
  }

  return aIndex - bIndex;
};

export const performNumericBubbleSort = (a: number, b: number): number => (a - b);

export const sortPerformanceReportUnitColumn = (a: any, b: any, target: string, granularity: string, insightKey: string) => {
  const aVal = a?.[target]?.rawValue;
  const bVal = b?.[target]?.rawValue;

  switch (insightKey) {
    case 'cashFlow':
    case 'cashFlowActual':
      return aVal - bVal;
    default:
      switch (granularity) {
        case 'monthly':
          return aVal - bVal;
        default:
          return typeof aVal === 'string'
            ? aVal?.localeCompare(bVal) || 0
            : 0;
      }
  }
};

export const sortPerformanceReportDataColumn = (a: any, b: any, target: string) => {
  const aVal = a?.[target]?.rawValue || 0;
  const bVal = b?.[target]?.rawValue || 0;
  return performNumericBubbleSort(aVal, bVal);
};

export const sortPerformanceReportTable = (a: any, b: any, target: string, insightKey, granularity: string = null) => {
  switch (target) {
    case 'unit':
      return sortPerformanceReportUnitColumn(a, b, target, granularity, insightKey);
    default:
      return sortPerformanceReportDataColumn(a, b, target);
  }
};

/**
  * Sorts the insight tree data by the order of the ReportVariableStructure
  * falling back to alphabetical order if not known in the ReportVariableStructure
  * @param {AntDesignTreeData[]} treeData - The tree data to sort
  * @returns {AntDesignTreeData[]} - The sorted tree data
  */
export const sortReportInsightTree = (treeData: AntDesignTreeData[]): AntDesignTreeData[] => (
  treeData.sort((a, b) => {
    // Sort parent level by the order of the ReportVariableStructure
    const aInx = Object
      .keys(ReportConstants.ReportVariableStructure)
      .indexOf(a.key);
    const bInx = Object
      .keys(ReportConstants.ReportVariableStructure)
      .indexOf(b.key);
    return getAlphabeticalFallbackBubbleSortValue(a.title, aInx, b.title, bInx);
  }).map((cat: AntDesignTreeData) => {
    const mutatedCat = cat;
    if (mutatedCat?.children) {
      mutatedCat.children.sort((a, b) => {
        const parsedAKey = a.key.split('_').pop();
        const parsedBKey = b.key.split('_').pop();
        const aInx = Object.keys(ReportConstants.ReportVariableStructure?.[mutatedCat.key])
          .findIndex((key) => key === parsedAKey || a.key.indexOf(key) !== -1);
        const bInx = Object.keys(ReportConstants.ReportVariableStructure?.[mutatedCat.key])
          .findIndex((key) => key === parsedBKey || b.key.indexOf(key) !== -1);
        return getAlphabeticalFallbackBubbleSortValue(a.title, aInx, b.title, bInx);
      }).map((child) => {
        const mutatedChild = child;
        const parsedChildKey = child.key.split('_').pop();
        if (mutatedChild?.children) {
          mutatedChild.children.sort((a, b) => {
            const parsedA = a.key.replace('{', '').replace('}', '');
            const parsedB = a.key.replace('{', '').replace('}', '');
            const aInx = ReportConstants.ReportVariableStructure?.[mutatedCat.key]?.[parsedChildKey]
              .indexOf(parsedA === 'operationalExpenses' ? 'opex' : parsedA);
            const bInx = ReportConstants.ReportVariableStructure?.[mutatedCat.key]?.[parsedChildKey]
              .indexOf(parsedB === 'operationalExpenses' ? 'opex' : parsedB);
            return getAlphabeticalFallbackBubbleSortValue(a.title, aInx, b.title, bInx);
          });
        }
        return mutatedChild;
      });
    }
    return mutatedCat;
  })
);

export const formatTableData = (
  selectedBusiness: any,
  financialYearEnd: any,
  graphData: any,
  settings: PracticeTypes.ReportSettingsObject,
  granularity: string,
  insightKey: string,
  localeStore: LocaleStore,
  filterItems: boolean = true
) => {
  const columnsLut = {};
  const columns = [];
  const data = [];
  const selectedItems = settings?.selectedItems;

  const format = new Format(selectedBusiness?.currencyCode, selectedBusiness?.countryCode);
  const financialStartDate = (parseInt(financialYearEnd.split('/')[1], 10) % 12) + 1;

  const unitHeader = localeStore.translation(`insights.${insightKey}.table.unit`);
  const unitCol: ColumnType<any> = {
    title: unitHeader,
    dataIndex: 'unit',
    key: 'unit',
    render: (val) => val?.formattedValue || val,
    sorter: (a, b) => sortPerformanceReportTable(a, b, 'unit', insightKey, granularity),
    className: 'reportInsightChartComponent__table--header',
  };

  if (settings?.sortTarget === 'unit') {
    unitCol.defaultSortOrder = settings.sortOrder as ColumnType<any>['defaultSortOrder'];
  }

  columns.push(unitCol);

  const addColumn = (label, key) => {
    if (!columnsLut[label]) {
      const column: ColumnType<any> = {
        title: label,
        dataIndex: key,
        key,
        align: 'right',
        render: (val) => val?.formattedValue,
        sorter: (a, b) => sortPerformanceReportTable(a, b, key, insightKey),
        className: 'reportInsightChartComponent__table--header',
      };
      if (key && settings?.sortTarget === key) {
        column.defaultSortOrder = settings.sortOrder as ColumnType<any>['defaultSortOrder'];
      }
      columns.push(column);
      columnsLut[label] = true;
    }
  };

  const monthMap = {
    Jan: 1,
    Feb: 2,
    Mar: 3,
    Apr: 4,
    May: 5,
    Jun: 6,
    Jul: 7,
    Aug: 8,
    Sep: 9,
    Oct: 10,
    Nov: 11,
    Dec: 12
  };

  const getDate = (label: string) => {
    if (typeof label === 'string') {
      const financialEnd = monthMap[label];
      const currentYear = DateTime.now().year;
      const year = financialEnd < financialStartDate ? currentYear : currentYear - 1;
      return DateTime.fromObject({ year, month: monthMap[label] });
    }
    return label;
  };

  if (graphData?.version === 2) {
    graphData.legend.forEach((legend, legendInx) => {
      const unit = {
        formattedValue: legend,
        rawValue: (
          ['cashFlow', 'cashFlowActual'].indexOf(insightKey) === -1
          && granularity === 'monthly'
        ) ? getDate(legend) : legend
      };
      const values: any = {};
      graphData.datasets.forEach((dataset, datasetInx) => {
        if (
          !dataset.data[legendInx]
          || (
            filterItems
            && selectedItems?.length >= 0
            && selectedItems?.indexOf(datasetInx) === -1
          )
        ) {
          return;
        }
        values[datasetInx] = {
          dataId: datasetInx,
          formattedValue: format.formatValue({ value: dataset.data[legendInx], format: ValueTypes.currency }),
        };
        addColumn(dataset.label, datasetInx);
      });
      data.push({
        unit,
        ...values
      });
    });
  } else if (graphData.type !== 'stackedGroupedBarChart') {
    graphData.data.forEach((datapoint) => {
      const unit = { formattedValue: datapoint.xLabel.toString(), rawValue: getDate(datapoint.xLabel) };
      const values = datapoint.values.reduce((acc, value) => {
        if (!value || !value.dataId
          || (
            filterItems
            && selectedItems?.length >= 0
            && selectedItems?.indexOf(value.dataId) === -1
          )
        ) return acc;
        const legend = graphData.legend.find((lgd) => lgd.dataId === value.dataId);
        addColumn(legend?.label, value.dataId);
        acc[value.dataId] = value;
        return acc;
      }, {});
      // data is the object for the whole row
      data.push({
        unit,
        ...values,
      });
    });
  } else {
    const dataArray = graphData.data[0];
    dataArray.xLabels.forEach((xLabel, index) => {
      const unit = { formattedValue: xLabel.toString(), rawValue: getDate(xLabel) };
      const values = dataArray.datasets.reduce((acc, datapoint) => {
        if (filterItems
          && selectedItems?.length >= 0
          && selectedItems?.indexOf(datapoint.label) === -1
        ) return acc;
        addColumn(datapoint.label, datapoint.label);
        acc[datapoint.label] = { formattedValue: format.formatValue({ value: datapoint.data[index], format: ValueTypes.currency }) };
        return acc;
      }, {});
      data.push({
        unit,
        ...values
      });
    });
  }

  return { columns, data };
};

export const formatChartAndTableName = ({
  title, units = '', isCurrency
}: {
  title: string,
  units?: string,
  isCurrency: boolean
}) => (isCurrency ? `${title} in ${units}` : `${units} ${title}`);

export const customEntityTransform = (entity, text) => {
  const variableKey = text.replace('#{', '').replace('}', '');

  if (!entity) {
    return null;
  }

  if (entity.type === 'IMAGE'
    || ReportEnums.ReportVariables?.[variableKey] === ReportEnums.VariableFormattingTypes.IMAGE
  ) {
    return `<img class='report-editor__logo' src="${text}" alt="${entity.data.alt}" />`;
  }

  return null;
};

export const focusEditorEnd = (ref) => {
  ref.focusEditor();
  const editorState = EditorState.moveSelectionToEnd(ref.state.editorState);
  const newState = EditorState.forceSelection(editorState, editorState.getSelection());
  return newState;
};
