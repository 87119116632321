import { ValueTypes } from '@aider/aider-formatting-library';
import type Format from '@aider/aider-formatting-library';
import _ from 'lodash-contrib';

// Format for chart values and Y-axis labels
export enum ChartValueFormats {
  reconciliation = ValueTypes.percentage,
  cashFlow = ValueTypes.currency,
  cashFlowActual = ValueTypes.currency,
  gst = ValueTypes.currency,
  incomeTax = ValueTypes.currency,
  invoiceStatus = ValueTypes.currency,
  revenue = ValueTypes.currency,
  directCosts = ValueTypes.currency,
  grossProfit = ValueTypes.currency,
  operationalExpenses = ValueTypes.currency,
  netProfit = ValueTypes.currency,
}

export const yAxisFormat = (
  title: string,
  unit: string | '',
  isCurrency: boolean
) => (isCurrency ? `${title} in ${unit}` : `${unit} ${title}`);

// SI Units for numerical values with their exponential dividers
export const numericalSeparatorMap = [
  { divider: 1e18, suffix: 'E' },
  { divider: 1e15, suffix: 'P' },
  { divider: 1e12, suffix: 'T' },
  { divider: 1e9, suffix: 'G' },
  { divider: 1e6, suffix: 'M' },
  { divider: 1e3, suffix: 'k' },
];

/**
  * Generates a shortened number with an SI symbol suffix based on the value
  * @example generateShortenedNumber(1000, format, ValueTypes.currency) => 1k
  * @param value - The value to be shortened
  * @param format - The initialised Aider Formatting Library class with desired localisation to be applied to the value
  * @param formatType - The type of format to be applied to the value
  * @returns The shortened number with a suffix
  * */
function generateShortenedNumber(value: number, format: Format, formatType: ValueTypes.text | ValueTypes.currency | ValueTypes.percentage = ValueTypes.text) {
  const absValue = Math.abs(value);
  let suffix = '';
  let dividedValue = value;

  numericalSeparatorMap.forEach((range) => {
    if (absValue >= range.divider) {
      dividedValue = value / range.divider;
      suffix = range.suffix;
    }
  });

  const shortVal = format?.formatValue({ value: dividedValue, format: formatType }) + suffix;
  return shortVal;
}

/**
  * Formats the value of a chart based on the insight key
  * @param value - The value to be formatted
  * @param insightKey - The key of the insight to determine the format
  * @param format - The initialised Aider Formatting Library class with desired localisation to be applied to the value
  * @returns The formatted value
  * */
export const formatChartValue = (value: number, insightKey: string, format: Format) => {
  if (ChartValueFormats[insightKey]) {
    return format?.formatValue({ value, format: ChartValueFormats[insightKey] });
  }
  return value;
};

/**
  * Formats the value of a chart's Y-axis based on the insight key
  * @param value - The value to be formatted
  * @param insightKey - The key of the insight to determine the format
  * @param format - The initialised Aider Formatting Library class with desired localisation to be applied to the value
  * @returns The formatted value
  * */
export const formatChartYAxisValue = (value: any, insightKey: string, format: Format) => {
  // Filter out anything that is not a number or string which correctly represents a number
  if (!_.isNumeric(value)) {
    return value;
  }

  // Convert to a number to allow processing
  const numericVal = parseFloat(value);

  // Only numbers from here on but we want to exclude decimals
  if (numericVal % 1 !== 0) {
    return null;
  }

  const valueFormat = ChartValueFormats?.[insightKey];
  if (valueFormat) {
    return valueFormat === ValueTypes.currency
      ? generateShortenedNumber(numericVal, format, valueFormat)
      : format?.formatValue({ value: numericVal, format: valueFormat });
  }
  return generateShortenedNumber(numericVal, format);
};

/**
  * RGB values for colors used in the graphs
  */
export enum RGBColorCodes {
  blue = '66, 99, 234', // Aider Blue
  teal = '0, 224, 225', // Aider Teal
  darkTeal = '3, 186, 187', // Aider Dark Teal
  darkerTeal = '0, 99, 110', // Aider Darker Teal
  white = '255, 255, 255', // White
  gray = '159, 160, 172', // Gray 8
  green = '148, 212, 168', // Green 3
  red = '255, 179, 171', // Red 3
}

/**
  * Generates an RGB color with an optional opacity
  * @param color - The RGB color code (use RGBColorCodes enum for predefined colors)
  * @param opacity - The opacity of the color (0 to 1)
  * @returns The RGB color with an optional opacity
  * */
export const generateRGBColor = (color: string, opacity: number = 1) => (
  `rgba(${color}, ${opacity})`
);

/** **************************************************************************
  * Color arrays for different graph types. Each entry represents a dataset
  * in the graph. The order of the arrays should match the order of the datasets
  ************************************************************************* */
export const lineHybridBorderArray = [
  generateRGBColor(RGBColorCodes.blue),
  generateRGBColor(RGBColorCodes.teal),
  generateRGBColor(RGBColorCodes.blue),
  generateRGBColor(RGBColorCodes.teal),
  generateRGBColor(RGBColorCodes.darkTeal),
  generateRGBColor(RGBColorCodes.darkerTeal),
];

export const lineHybridBgArray = [
  generateRGBColor(RGBColorCodes.blue),
  generateRGBColor(RGBColorCodes.teal),
  generateRGBColor(RGBColorCodes.blue, 0.1),
  generateRGBColor(RGBColorCodes.teal, 0.1),
  generateRGBColor(RGBColorCodes.darkTeal, 0.1),
  generateRGBColor(RGBColorCodes.darkerTeal, 0.1),
];

export const lineHybridSuccessBorderArray = [
  generateRGBColor(RGBColorCodes.blue),
  generateRGBColor(RGBColorCodes.green),
  generateRGBColor(RGBColorCodes.blue),
  generateRGBColor(RGBColorCodes.green),
  generateRGBColor(RGBColorCodes.darkTeal),
  generateRGBColor(RGBColorCodes.darkerTeal),
];

export const lineHybridSuccessBgArray = [
  generateRGBColor(RGBColorCodes.blue),
  generateRGBColor(RGBColorCodes.green),
  generateRGBColor(RGBColorCodes.blue, 0.1),
  generateRGBColor(RGBColorCodes.green, 0.1),
  generateRGBColor(RGBColorCodes.darkTeal, 0.1),
  generateRGBColor(RGBColorCodes.darkerTeal, 0.1),
];

export const lineHybridDangerBorderArray = [
  generateRGBColor(RGBColorCodes.blue),
  generateRGBColor(RGBColorCodes.red),
  generateRGBColor(RGBColorCodes.blue),
  generateRGBColor(RGBColorCodes.red),
  generateRGBColor(RGBColorCodes.darkTeal),
  generateRGBColor(RGBColorCodes.darkerTeal),
];

export const lineHybridDangerBgArray = [
  generateRGBColor(RGBColorCodes.blue),
  generateRGBColor(RGBColorCodes.red),
  generateRGBColor(RGBColorCodes.blue, 0.1),
  generateRGBColor(RGBColorCodes.red, 0.1),
  generateRGBColor(RGBColorCodes.darkTeal, 0.1),
  generateRGBColor(RGBColorCodes.darkerTeal, 0.1),
];

export const lineBorderArray = [
  generateRGBColor(RGBColorCodes.blue),
  generateRGBColor(RGBColorCodes.teal),
  generateRGBColor(RGBColorCodes.darkTeal),
];

export const lineBgArray = [
  generateRGBColor(RGBColorCodes.blue, 0.1),
  generateRGBColor(RGBColorCodes.teal, 0.1),
  generateRGBColor(RGBColorCodes.darkTeal, 0.1),
];

export const pointBgArray = [
  generateRGBColor(RGBColorCodes.blue),
  generateRGBColor(RGBColorCodes.teal),
  generateRGBColor(RGBColorCodes.blue),
  generateRGBColor(RGBColorCodes.teal),
  generateRGBColor(RGBColorCodes.darkTeal),
  generateRGBColor(RGBColorCodes.darkerTeal),
];

export const barBorderArray = [
  generateRGBColor(RGBColorCodes.blue),
  generateRGBColor(RGBColorCodes.blue),
  generateRGBColor(RGBColorCodes.gray, 0.67),
  generateRGBColor(RGBColorCodes.gray, 0.67),
  generateRGBColor(RGBColorCodes.blue, 0.5),
  generateRGBColor(RGBColorCodes.gray, 0.67),
];

export const barBgArray = [
  [generateRGBColor(RGBColorCodes.blue, 0.58), generateRGBColor(RGBColorCodes.blue, 0.58)],
  [generateRGBColor(RGBColorCodes.white), generateRGBColor(RGBColorCodes.white)],
  [generateRGBColor(RGBColorCodes.gray, 0.67), generateRGBColor(RGBColorCodes.gray, 0.67)],
];

export const groupedBarBgArray = [
  generateRGBColor(RGBColorCodes.blue, 0.58),
  generateRGBColor(RGBColorCodes.white),
  generateRGBColor(RGBColorCodes.gray, 0.67),
  generateRGBColor(RGBColorCodes.white),
];

/**
  * Graph formats for different insights
  * each insight has an array of objects which represents
  * the type of graph and the style of the graph for each dataset
  * expected in the order of the datasets
  */
export const GraphFormats = {
  cashFlow: [
    { type: 'line' },
    { type: 'line', style: 'dashed' },
    { type: 'line', style: 'dashed' },
  ],
  cashFlowActual: [
    { type: 'line' },
    { type: 'line' },
    { type: 'line' },
  ],
  operationalExpenses: [
    { type: 'bar' },
    { type: 'bar' },
    { type: 'line', style: 'filled' },
    { type: 'line' },
    { type: 'line' },
  ],
  reconciliation: [
    { type: 'bar', style: 'stacked' },
    { type: 'bar', style: 'stacked' },
    { type: 'bar', style: 'stacked' },
    { type: 'bar', style: 'stacked' },
    { type: 'bar', style: 'stacked' },
    { type: 'bar', style: 'stacked' },
  ],
  incomeTax: [
    { type: 'bar' },
    { type: 'bar' },
    { type: 'line', style: 'filled' },
    { type: 'line' },
  ],
  invoiceStatus: [
    { type: 'bar' },
    { type: 'bar' },
    { type: 'bar' },
    { type: 'bar' },
  ],
  gst: [
    { type: 'bar' },
    { type: 'bar' },
    { type: 'bar' },
    { type: 'bar' },
    { type: 'bar' },
    { type: 'bar' },
  ],
  directCosts: [
    { type: 'bar' },
    { type: 'bar' },
    { type: 'line', style: 'filled' },
    { type: 'line' },
    { type: 'line' },
    { type: 'line' },
  ],
  revenue: [
    { type: 'bar' },
    { type: 'bar' },
    { type: 'line', style: 'filled' },
    { type: 'line' },
    { type: 'line' },
    { type: 'line' },
  ],
  grossProfit: [
    { type: 'bar' },
    { type: 'bar' },
    { type: 'line', style: 'filled' },
    { type: 'line' },
    { type: 'line' },
    { type: 'line' },
  ],
  netProfit: [
    { type: 'bar' },
    { type: 'bar' },
    { type: 'line', style: 'filled' },
    { type: 'line' },
    { type: 'line' },
    { type: 'line' },
  ],
};

/**
  * Helper functions to get the border and background colors for the graphs
  * depending on the insight key and the index of the dataset
  */
export const getBorderColor = (insightKey: string, index: number, budget: boolean = false) => {
  switch (insightKey) {
    case 'invoiceStatus':
    case 'gst':
    case 'reconciliation':
      return barBorderArray[index];
    case 'revenue':
    case 'grossProfit':
    case 'netProfit':
      return budget ? lineHybridSuccessBorderArray[index] : lineHybridBorderArray[index];
    case 'directCosts':
    case 'operationalExpenses':
      return budget ? lineHybridDangerBorderArray[index] : lineHybridBorderArray[index];
    case 'cashFlow':
    case 'cashFlowActual':
      return lineBorderArray[index];
    default:
      return lineHybridBorderArray[index];
  }
};

export const getBackgroundColor = (insightKey: string, index: number, budget: boolean = false) => {
  switch (insightKey) {
    case 'gst':
    case 'reconciliation':
      // Bar Chart
      return barBgArray[index];
    case 'invoiceStatus':
      // SG Bar Chart
      return groupedBarBgArray[index];
    case 'revenue':
    case 'grossProfit':
    case 'netProfit':
      return budget ? lineHybridSuccessBgArray[index] : lineHybridBgArray[index];
    case 'directCosts':
    case 'operationalExpenses':
      return budget ? lineHybridDangerBgArray[index] : lineHybridBgArray[index];
    case 'cashFlow':
    case 'cashFlowActual':
      return lineBgArray[index];
    default:
      // Line / Hybrid Chart
      return lineHybridBgArray[index];
  }
};

export const getPointBackgroundColor = (insightKey: string, index: number) => {
  switch (insightKey) {
    case 'cashflow':
    case 'cashflowActual':
      return barBorderArray[index];
    default:
      return pointBgArray[index];
  }
};

/**
  * Converts v1 graph data to v2 graph data
  * To be removed once v2 graph data is available
  */
export const convertGraphData = (rawData: any) => {
  let legend = [];
  let annotation;

  if (rawData?.yAxis?.annotations) {
    annotation = {
      label: rawData?.yAxis?.annotations[0]?.label,
      value: rawData?.yAxis?.annotations[0]?.rawValue,
    };
  }

  let datasets;

  if (rawData?.data?.length === 1) {
    // Invoice Status
    legend = rawData?.data?.[0]?.xLabels;
    datasets = rawData?.data?.[0]?.datasets?.map((dataset) => ({
      label: dataset.label,
      data: dataset.data,
      stack: dataset.stack,
    }));
  } else {
    // All Others
    datasets = rawData?.data?.reduce((acc, current) => {
      if (!current?.values) return acc;
      if (!legend.includes(current.xLabel) && current.xLabel) {
        legend.push(current.xLabel);
      }
      current.values.forEach((item) => {
        if (!item) return;

        const label = item?.label || rawData?.legend?.find((legendItem) => legendItem.dataId === item.dataId)?.label;
        if (!label) return;

        const existingLabel = acc?.find((entry) => entry.label === label);

        if (existingLabel) {
          existingLabel.data.push(item.rawValue);
        } else {
          acc.push({
            label,
            data: [item.rawValue],
          });
        }
      });

      return acc;
    }, []);
  }

  return {
    legend,
    annotation,
    datasets,
  };
};
