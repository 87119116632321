/* eslint-disable global-require */
import { makeAutoObservable } from 'mobx';
import _ from 'lodash';
import { CatchAll } from '@aider/constants-library/dist/types/generic';
import Format, { DateFormats, ValueTypes } from '@aider/aider-formatting-library';
import { DateTime } from 'luxon';
import type { RootStore } from './Store';

import handleError from '../lib/errorHandler';

const langfiles = {
  en: require('../locales/en.json'),
  'en-US': require('../locales/en-US.json'),
  'en-AU': require('../locales/en-AU.json'),
};

export default class LocaleStore {
  rootStore: RootStore;

  format: Format;

  loading: string[] = [];

  defaultLocale: any;

  locale: any;

  get practiceFormat(): Format {
    return new Format(
      undefined,
      this.rootStore.practiceStore.practice?.countryCode,
      this.rootStore.practiceStore.practice?.timeZoneId
    );
  }

  get clientFormat(): Format {
    return new Format(this.rootStore.businessesStore.selectedBusiness?.currencyCode, this.rootStore.businessesStore.selectedBusinessCountryCode);
  }

  setLocale = (countryCode: string) => {
    const localeCode = this.availableLocales[countryCode];
    if (!localeCode) {
      this.locale = null;
      return;
    }
    this.loadLocale(localeCode)
      .then((locale) => {
        this.locale = locale;
      })
      .catch((error) => {
        handleError({ error });
        this.locale = null;
      });
  };

  availableLocales = {
    US: 'en-US',
    AU: 'en-AU',
  };

  loadLocale = async (localeCode: string) => langfiles?.[localeCode];

  getLocaleTranslation = (key: string) => _.get(this.locale, key);

  getDefaultTranslation = (key: string) => _.get(this.defaultLocale, key);

  translation = (key: string, options?: CatchAll<string | number>) => {
    const translation =
      this.getLocaleTranslation(key) || this.getDefaultTranslation(key);
    if (!translation && translation !== '') {
      return `[Translation not found: ${key}]`;
    }
    if (options) {
      try {
        return _.template(translation)(options);
      } catch (error) {
        handleError({ error });
        return `[Translation error: ${key}]`;
      }
    }
    return translation;
  };

  normaliseDate = (date: string, locale: string = 'en-NZ') => {
    const isoDateRegex = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}/;
    const isIsoDate = isoDateRegex.test(date);
    if (isIsoDate) {
      return { isoDate: DateTime.fromISO(date, { locale, setZone: true }) };
    }

    const slashDateRegex = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/;
    const isSlashDate = slashDateRegex.test(date);
    if (isSlashDate) {
      return { normalisedDate: date };
    }

    const hyphenDateRegex = /^(0[1-9]|[12][0-9]|3[01])-(0[1-9]|1[0-2])-\d{4}$/;
    const isHyphenDate = hyphenDateRegex.test(date);
    if (isHyphenDate) {
      return { normalisedDate: date.replace(/-/g, '/'), preformatted: false };
    }

    const reversedRegex = /^(\d{4})[-/](0[1-9]|1[0-2])[-/](0[1-9]|[12][0-9]|3[01])$/;
    const isReversed = reversedRegex.test(date);
    if (isReversed) {
      return { normalisedDate: date.replace(reversedRegex, '$3/$2/$1'), preformatted: false };
    }

    return null;
  };

  formatDate = (
    date: string,
    type: DateFormats,
    firestoreTimestamp: any = null
  ) => {
    if (!this.format) {
      this.format = new Format(
        null,
        this.rootStore.practiceStore.practice?.countryCode || 'NZ',
        this.rootStore.practiceStore.practice?.timeZoneId || 'Pacific/Auckland'
      );
    }

    return this.format.localiseDate(date, type, firestoreTimestamp);
  }

  localisedCurrency = (value: number) => {
    if (this.clientFormat.country !== this.practiceFormat.country) {
      this.clientFormat.currencySymbol = this.clientFormat.fullCurrencySymbol;
    }
    return this.clientFormat.formatValue({
      value,
      format: ValueTypes.currency,
    });
  }

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(
      this,
      {
        rootStore: false,
      },
      { autoBind: true }
    );

    this.rootStore.loadingStore.setLoading('locale');

    this.loadLocale('en').then((locale) => {
      this.defaultLocale = locale;
      this.rootStore.loadingStore.setDoneLoading('locale');
    });
  }
}
