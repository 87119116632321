import { makeAutoObservable } from 'mobx';
import * as Sentry from '@sentry/browser';
import { PeriodTypes } from '@aider/aider-period-library';
import { ApiConstants } from '@aider/constants-library';
import { CatchAll } from '@aider/constants-library/dist/types/generic';
import { GET } from '../lib/requests';
import type { RootStore } from './Store';

export class InsightStore {
  rootStore: RootStore;

  insightMap: Map<string, any> = new Map();

  insightGraphs: CatchAll<string> = {};

  activeType: 'financials' | 'budgets' = 'financials';

  selectedGranularity: PeriodTypes = PeriodTypes.MONTHLY;

  selectedProfitabilityPeriod: string;

  selectedBudgetKey: string;

  insightBudgets = [];

  categoryInsightGroups = {
    profitability: [
      'revenue',
      'operationalExpenses',
      'grossProfit',
      'directCosts',
      'netProfit',
    ],
    cashflow: ['cashFlow', 'invoiceStatus', 'cashFlowActual'],
    taxAndCompliance: ['reconciliation', 'gst', 'incomeTax']
  };

  get isBudgetsActive() {
    return this.activeType === 'budgets';
  }

  get selectedBudgetName() {
    let budget;
    if (this.selectedBudgetKey) {
      budget = this.getPeriodInsight('revenue')?.budgets?.find((b) => b.budgetId === this.selectedBudgetKey);
    } else {
      budget = this.getPeriodInsight('revenue')?.budgets?.slice().sort((a, b) => a?.budgetName.localeCompare(b?.budgetName))?.[0];
    }
    return budget?.budgetName || null;
  }

  get availableBudgets() {
    const periodInsight = this.getPeriodInsight('revenue');
    const periodBudgets = periodInsight?.budgets?.map((budget) => ({
      key: budget?.budgetId,
      label: budget?.budgetName,
      className: `budget-selector-item ${this.selectedBudgetKey === budget.key ? 'budget-selector-item--active' : ''}`,
    }));
    return periodBudgets?.sort((a, b) => a.label.localeCompare(b.label)) || [];
  }

  get insightData() {
    if (this.insightMap.has(this.rootStore.businessesStore.selectedBusinessId)) {
      return this.insightMap.get(this.rootStore.businessesStore.selectedBusinessId);
    }
    return null;
  }

  get reconciliationStatus() {
    const insightWithReconciliationStatus = this.insightMap.get(this.rootStore.businessesStore.selectedBusinessId)?.find((insight) => insight.reconciliationStatus);
    return insightWithReconciliationStatus?.reconciliationStatus || null;
  }

  getInsightCategory(insightKey: string) {
    return Object.keys(this.categoryInsightGroups).find((category) => this.categoryInsightGroups[category].includes(insightKey));
  }

  getPeriodInsight(insightKey: string) {
    if (!this.insightMap.has(this.rootStore.businessesStore.selectedBusinessId)) {
      return null;
    }

    const insight = this.insightMap
      .get(this.rootStore.businessesStore.selectedBusinessId)
      ?.find((i: any) => i.insightKey === insightKey);

    let periodInsight: any;
    switch (this.selectedGranularity) {
      case PeriodTypes.MONTHLY:
        periodInsight = insight?.periods
          ?.find((period: any) => period?.periodData?.name === this.selectedProfitabilityPeriod);
        break;
      case PeriodTypes.QUARTERLY:
        periodInsight = insight?.quarters
          ?.find((period: any) => period?.periodData?.name === this.selectedProfitabilityPeriod);
        break;
      default:
        periodInsight = insight;
    }

    return periodInsight;
  }

  getPeriodOrBudgetInsight(insightKey: string) {
    let periodInsight = this.getPeriodInsight(insightKey);

    if (this.activeType === 'budgets') {
      periodInsight = periodInsight?.budgets?.find((budget) => budget.budgetId === this.selectedBudgetKey) || periodInsight?.budgets?.[0];
    }

    return periodInsight;
  }

  getFallbackInsight(insightKey: string) {
    const baseInsight = this.getPeriodInsight(insightKey);
    if (!baseInsight || baseInsight?.missing) {
      return null;
    }

    const fallbackInsight:any = {
      version: 2,
      insightKey,
      periodData: baseInsight?.periodData,
      datapoints: baseInsight?.datapoints,
      summaries: baseInsight?.summaries?.map((summary) => ({
        datapointsV2: summary?.datapointsV2,
        datapointFormats: summary?.datapointFormats,
        disabled: summary?.disabled,
        id: summary?.id,
        summaryKey: summary?.summaryKey,
        shownOnDashboard: summary?.shownOnDashboard,
        status: 'info',
        templateData: summary?.templateData,
        value: summary?.value,
      })),
    };

    if (baseInsight?.reconciliationStatus) {
      fallbackInsight.reconciliationStatus = baseInsight.reconciliationStatus;
    }

    fallbackInsight.graph = baseInsight?.graph || {};
    fallbackInsight.graph.datasets = fallbackInsight?.graph?.datasets?.map((dataset, inx) => {
      const mutatedDataset = { ...dataset };
      switch (insightKey) {
        case 'revenue':
        case 'operationalExpenses':
        case 'directCosts':
        case 'netProfit':
        case 'grossProfit':
          if (inx !== 0 && inx !== 2) {
            mutatedDataset.data = [];
          }
          break;
        default:
          if (inx !== 0) {
            mutatedDataset.data = [];
          }
      }
      return mutatedDataset;
    });

    return fallbackInsight;
  }

  getInsight(insightKey: string) {
    if (this.insightMap.has(this.rootStore.businessesStore.selectedBusinessId)) {
      return this.insightMap.get(this.rootStore.businessesStore.selectedBusinessId).find((insight) => insight.insightKey === insightKey);
    }
    return null;
  }

  getInsightBudgets(insightKey: string) {
    const insightData = this.getInsight(insightKey);
    if (insightData) {
      return insightData?.budgets || null;
    }
    return null;
  }

  get insightsLoading() {
    return !this.insightMap.has(this.rootStore.businessesStore.selectedBusinessId);
  }

  setInsightGraph(insightKey: string, graph: string) {
    this.insightGraphs[insightKey] = graph;
  }

  getInsightGraph(insightKey: string) {
    return this.insightGraphs?.[insightKey] || null;
  }

  async getBusinessInsights(businessId: string = this.rootStore.businessesStore.selectedBusinessId) {
    const transaction = Sentry.startTransaction({
      name: 'Load Business Insights',
    });
    const url = `${ApiConstants.apiEndpointsBase.insightManager
    }/businesses/${businessId}/insights`;

    GET({
      url,
      rootStore: this.rootStore,
      sentryTransaction: transaction,
      sentrySpanName: 'getBusinessInsights'
    }).then((res) => {
      this.insightMap.set(businessId, res.insights);
      transaction.finish();
      return res.insights;
    });
  }

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this, {
      rootStore: false,
    }, { autoBind: true });
  }
}
