import React from 'react';
import { Form, Table } from 'antd';
import { observer } from 'mobx-react';
import { PracticeTypes } from '@aider/constants-library';
import { convertFromRaw } from 'draft-js';
import { useStore } from '../../../stores/Store';
import AiderAlert from '../../AiderAlert';
import { formatTableData } from '../../../lib/componentHelpers/reportHelpers';

const TableComponent = ({
  className,
  block,
}: {
  className: string,
  block: PracticeTypes.ReportBlock,
}) => {
  const { localeStore, reportTemplateStore, businessesStore } = useStore();
  const isEditing = reportTemplateStore.editBlock === block.id;
  const { filteredInsightGraphData } = reportTemplateStore;
  const { selectedBusiness, selectedBusinessFinancialYearEnd } = businessesStore;
  return (
    <Form.Item
      className={`${className} ${className}--${isEditing ? 'editing' : 'read-only'} ${className}--${block.type}`}
      name={block.id}
      label={null}
      initialValue={block.content}
    >
      {
        block.content ? (
          (() => {
            const insightKey = convertFromRaw(block.content).getPlainText();
            let parsedInsightKey = insightKey;
            let isBudgetTable = false;
            let selectedInsight;
            if (insightKey.indexOf('budget') !== -1) {
              isBudgetTable = true;
              const [baseInsight, , budgetIndex] = insightKey.split('-');
              parsedInsightKey = baseInsight;
              selectedInsight = filteredInsightGraphData.find((insight) => insight.insightKey === baseInsight)?.budgets[budgetIndex];
            } else {
              selectedInsight = filteredInsightGraphData.find((insight) => insight.insightKey === insightKey);
            }

            if (selectedInsight) {
              const formattedData = formatTableData(
                selectedBusiness,
                selectedBusinessFinancialYearEnd,
                selectedInsight.graph,
                reportTemplateStore.selectedPerformanceReport?.settings?.[block.id],
                reportTemplateStore.selectedPeriodData?.granularity,
                parsedInsightKey,
                localeStore,
              );

              return (
                <>
                  <Table
                    size='small'
                    className='reportInsightTableComponent__table'
                    columns={formattedData.columns}
                    dataSource={formattedData.data}
                    pagination={false}
                    scroll={{ x: true }}
                    onChange={(p, f, sorter: any) => { // eslint-disable-line @typescript-eslint/no-unused-vars
                      const settings:PracticeTypes.ReportSettingsObject = {
                        sortOrder: sorter?.order,
                        sortTarget: sorter?.column?.dataIndex,
                      };

                      reportTemplateStore.saveReportSettings(block.id, settings);
                    }}
                  />
                  <p className='reportInsightTableComponent__table--description'>
                    {localeStore.translation(`insights.${selectedInsight.insightKey}.graph.yAxis`, {
                      currency: businessesStore.selectedBusiness.currencyCode,
                      currencySymbol: businessesStore.selectedBusiness.currencySymbol
                    })}
                  </p>
                </>
              );
            }
            return (
              <AiderAlert
                type='warning'
                title={`${localeStore.translation(`insights.${parsedInsightKey}.title`)}${isBudgetTable ? ' budget' : ''} insight data not available for selected period`}
              />
            );
          })()
        ) : (
          <AiderAlert
            title='Empty table block'
          />
        )
      }
    </Form.Item>
  );
};

export default observer(TableComponent);
