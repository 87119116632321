import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useLocation } from 'react-router-dom';
import { useStore } from '../stores/Store';
import { Routers } from '../models/enums/utils';

function TimeoutProvider({ children }: { children: JSX.Element }) {
  const location = useLocation();
  const rootStore = useStore();
  const [scrollableElements, setScrollableElements] = useState(null);

  const exemptedRoutes = [Routers.LOGIN];

  useEffect(() => {
    if (exemptedRoutes.includes(location.pathname as Routers)) {
      // Clean up any existing timeouts when landing on an exempt route
      rootStore.removeTimeout();
      return () => null;
    }
    const handleWindowEvents = () => {
      rootStore.createTimeout();
    };

    // listen for specific window events to ensure user is still active
    window.addEventListener('mousemove', handleWindowEvents);
    window.addEventListener('keydown', handleWindowEvents);
    window.addEventListener('click', handleWindowEvents);
    window.addEventListener('scroll', handleWindowEvents);
    const scrollables = document.querySelectorAll('.scrollbar');
    setScrollableElements(scrollables);

    if (scrollables.length) {
      scrollables.forEach((element) => {
        element.addEventListener('scroll', handleWindowEvents);
      });
    }

    if (!rootStore.timeoutId) {
      handleWindowEvents();
    }

    // cleanup function
    return () => {
      window.removeEventListener('mousemove', handleWindowEvents);
      window.removeEventListener('keydown', handleWindowEvents);
      window.removeEventListener('click', handleWindowEvents);
      window.removeEventListener('scroll', handleWindowEvents);
      if (scrollableElements?.length) {
        scrollableElements.forEach((element) => {
          element.removeEventListener('scroll', handleWindowEvents);
        });
      }
    };
  }, [location.pathname, rootStore.loadingStore.isAnyLoading, rootStore.insightStore.insightsLoading, rootStore.reportTemplateStore.fetchingVariables]);

  return children;
}

export default observer(TimeoutProvider);
