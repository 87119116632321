import { LevelFormat, LineRuleType, convertInchesToTwip } from 'docx';

// Define the styles and numbering for the word document
// NB: The color values are in hex format without leading #, e.g. 'FF0000' for red
// NB: Font size is in half points: 72 = 36pt
// NB: Font is web safe font to ensure the font is available on all systems
const headingColor = '0A1E70'; // Dark blue
const bodyColor = '272830'; // Dark grey
const fontFamily = 'Trebuchet MS';

export const DOCX_CONFIG = {
  styles: {
    default: {
      title: {
        run: {
          color: headingColor,
          font: fontFamily,
          size: 72,
        },
        paragraph: {
          spacing: { line: 24 * 10 * 1.1, lineRule: LineRuleType.AUTO },
        }
      },
      heading1: {
        run: {
          color: headingColor,
          font: fontFamily,
          size: 44,
        },
        paragraph: {
          spacing: { line: 24 * 10 * 1.1, lineRule: LineRuleType.AUTO },
        }
      },
      heading2: {
        run: {
          color: headingColor,
          font: fontFamily,
          size: 36,
        },
        paragraph: {
          spacing: { line: 24 * 10 * 1.1, lineRule: LineRuleType.AUTO },
        }
      },
      heading3: {
        run: {
          color: headingColor,
          font: fontFamily,
          size: 32,
        },
        paragraph: {
          spacing: { line: 24 * 10 * 1.1, lineRule: LineRuleType.AUTO },
        }
      },
      heading4: {
        run: {
          color: headingColor,
          font: fontFamily,
          size: 28,
        },
        paragraph: {
          spacing: { line: 24 * 10 * 1.1, lineRule: LineRuleType.AUTO },
        }
      },
      heading5: {
        run: {
          color: headingColor,
          font: fontFamily,
          bold: true,
          size: 24,
        },
        paragraph: {
          spacing: { line: 24 * 10 * 1.1, lineRule: LineRuleType.AUTO },
        }
      },
      heading6: {
        run: {
          color: bodyColor,
          font: fontFamily,
          bold: true,
          size: 24,
        },
        paragraph: {
          spacing: { line: 24 * 10 * 1.1, lineRule: LineRuleType.AUTO },
        }
      },
      listParagraph: {
        run: {
          font: fontFamily,
          color: bodyColor,
          size: 20,
        },
        paragraph: {
          spacing: { line: 24 * 10 * 1.3, lineRule: LineRuleType.AUTO },
        }
      },
      document: {
        run: {
          font: fontFamily,
          color: bodyColor,
          size: 20,
        },
        paragraph: {
          spacing: { line: 24 * 10 * 1.3, lineRule: LineRuleType.AUTO },
        }
      },
    },
  },
  numbering: {
    config: [
      {
        reference: 'block-numbering',
        levels: [
          {
            level: 0,
            format: LevelFormat.DECIMAL,
            text: '%1.',
            style: {
              paragraph: {
                indent: { left: convertInchesToTwip(0.5), hanging: convertInchesToTwip(0.25) },
              }
            }
          },
          {
            level: 1,
            format: LevelFormat.LOWER_LETTER,
            text: '%2.',
            style: {
              paragraph: {
                indent: { left: convertInchesToTwip(1), hanging: convertInchesToTwip(0.25) },
              }
            }
          },
          {
            level: 2,
            format: LevelFormat.LOWER_ROMAN,
            text: '%3.',
            style: {
              paragraph: {
                indent: { left: convertInchesToTwip(1.5), hanging: convertInchesToTwip(0.25) },
              }
            }
          },
          {
            level: 3,
            format: LevelFormat.UPPER_LETTER,
            text: '%4.',
            style: {
              paragraph: {
                indent: { left: convertInchesToTwip(2), hanging: convertInchesToTwip(0.25) },
              }
            }
          },
          {
            level: 4,
            format: LevelFormat.UPPER_ROMAN,
            text: '%5.',
            style: {
              paragraph: {
                indent: { left: convertInchesToTwip(2.5), hanging: convertInchesToTwip(0.25) },
              }
            }
          },
        ]
      },
    ],
  }
};
