import React from 'react';
import { CheckCircleFilled, EditOutlined } from '@ant-design/icons';
import { PracticeTypes } from '@aider/constants-library';
import { observer } from 'mobx-react';
import ButtonSecondary from '../../customAntD/ButtonSecondary';
import { useStore } from '../../../stores/Store';
import ReportBlockSettingsModal from '../../modals/ReportBlockSettingsModal';

const ChartActions = ({
  block,
  className
}: {
  block: PracticeTypes.ReportBlock,
  className: string
}) => {
  const { reportTemplateStore } = useStore();
  const isEditing = reportTemplateStore.editBlock === block.id;

  return (
    <aside className={className}>
      {!isEditing && (
        <>
          <ButtonSecondary
            className='borderless'
            icon={<EditOutlined />}
            mixpanelEvent='Report Editor - Edit Graph'
            mixpanelProperties={{ editBy: 'Action Button' }}
            onClick={() => {
              reportTemplateStore.editBlock = block.id;
              ReportBlockSettingsModal('chart', reportTemplateStore);
            }}
          />
        </>
      )}
      {isEditing && (
        <>
          <ButtonSecondary
            className='borderless save-button'
            icon={<CheckCircleFilled />}
            mixpanelEvent='Report Editor - Save Graph'
            onClick={() => {
              reportTemplateStore.editBlock = null;
            }}
          />
        </>
      )}
    </aside>
  );
};

export default observer(ChartActions);
