import React from 'react';
import { Outlet } from 'react-router-dom';
import Root from '../components/Root';
import TimeoutProvider from '../utils/TimeoutProvider';

const ProtectedRoutes = () => (
  <>
    <Root />
    <TimeoutProvider>
      <Outlet />
    </TimeoutProvider>
  </>
);

export default ProtectedRoutes;
