import React from 'react';
import { observer } from 'mobx-react';
import { useStore } from '../../stores/Store';
import ReconciliationStatusBanner from './ReconciliationStatusBanner';
import InsightSummaryContainer from './InsightSummaryContainer';
import AiderGraph from '../chart/AiderGraph';
import InsightSettings from './InsightSettings';
import pointUp from '../../entities/icons/icon_cursor-hand.png';
import EarlyWarningHeaderIcon from './EarlyWarning/EarlyWarningHeaderIcon';
import InsightTrends from './InsightTrends';
import InsightTips from './InsightTips';
import MissingInsight from './MissingInsight';

const InsightContainer = ({ insightKey }) => {
  const { businessesStore, insightStore, localeStore } = useStore();

  const [insightData, setInsightData] = React.useState(null);

  React.useEffect(() => {
    const periodInsight = insightStore.getPeriodOrBudgetInsight(insightKey);
    let fallbackInsight;
    if (!periodInsight) {
      fallbackInsight = insightStore.getFallbackInsight(insightKey);
    }
    setInsightData(periodInsight || fallbackInsight);
  }, [
    insightKey,
    businessesStore.selectedBusinessId,
    insightStore.selectedGranularity,
    insightStore.selectedProfitabilityPeriod,
    insightStore.isBudgetsActive,
    insightStore.selectedBudgetKey
  ]);

  return insightKey && (
    !insightData ? (
      <MissingInsight
        insightKey={insightKey}
        error={insightData?.error}
      />
    ) : (
      <section className={`insight-container insight-container__${insightData?.reconciliationStatus?.status !== 'danger' ? 'success' : 'danger'}`}>
        <h3>
          { localeStore.translation(`insights.${insightKey}.title`) }
          { insightData?.datapoints?.estimatedConfidence && (
            <EarlyWarningHeaderIcon />
          )}
        </h3>
        <ReconciliationStatusBanner insightData={insightData} />
        <InsightSummaryContainer summaries={insightData?.summaries} insightKey={insightKey} />
        <div className='insight-container__details'>
          <aside className='insight-container__details--column'>
            <AiderGraph
              insightKey={insightKey}
              graphData={insightData?.graph}
              isBudgetGraph={insightStore.isBudgetsActive}
            />
            <div className='insight-container__details--graphHelp'>
              <img
                src={pointUp}
                alt='Finger pointing up at the graph and legend'
              />
              <h5>Interactive Graph:</h5>
              <p>Click on the keys in the legend to hide or show a graph element.</p>
            </div>
            <InsightTips insightKey={insightKey} />
          </aside>
          <aside className='insight-container__details--column'>
            <InsightSettings insightKey={insightKey} />
            <InsightTrends insightKey={insightKey} insightData={insightData} />
          </aside>
        </div>
      </section>
    )
  );
};

export default observer(InsightContainer);
