import React from 'react';
import NiceModal from '@ebay/nice-modal-react';
import { SettingOutlined } from '@ant-design/icons';
import { ClientSettingsPages, ModalWidths } from '../../models/enums/modals';
import ModalNavigationLayout from '../../layouts/ModalNavigationLayout';
import ClientInformation from './content/ClientInformation';
import DeactivateClient from './content/DeactivateClient';
import RemoveClient from './content/RemoveClient';
import ClientConfiguration from './content/ClientConfiguration';
import AsyncModal from './wrappers/AsyncModal';

/**
 * The modal for the paywall modal
 */
const ClientSettingsModal = (rootStore, targetPage = ClientSettingsPages.CLIENT_INFORMATION) => {
  let initialIndex;
  switch (targetPage) {
    case ClientSettingsPages.REMOVE_CLIENT:
      initialIndex = 2;
      break;
    case ClientSettingsPages.CLIENT_INFORMATION:
      initialIndex = 1;
      break;
    case ClientSettingsPages.CLIENT_CONFIGURATION:
    default:
      initialIndex = 0;
      break;
  }

  const links = [
    {
      id: ClientSettingsPages.CLIENT_CONFIGURATION,
      label: 'Client Settings',
      content: <ClientConfiguration />
    },
    {
      id: ClientSettingsPages.CLIENT_INFORMATION,
      label: 'Client Info',
      content: <ClientInformation />
    },
    {
      id: ClientSettingsPages.DEACTIVATE_CLIENT,
      label: 'Deactivate client',
      content: <DeactivateClient />
    },
    {
      id: ClientSettingsPages.REMOVE_CLIENT,
      label: 'Remove client',
      content: <RemoveClient />
    },
  ];

  NiceModal.register('ClientSettingsModal', AsyncModal);

  const ModalProps = {
    name: 'Client Settings Modal',
    title: (<><SettingOutlined /> Client Settings</>),
    className: 'client-settings-modal',
    footer: false,
    width: ModalWidths.XLARGE,
    centered: false,
    content: (<ModalNavigationLayout layoutName='Client Settings Modal' links={links} initialIndex={initialIndex} />),
    afterClose: () => {
      NiceModal.remove('ClientSettingsModal');
    }
  };

  let accountsPromise = null;
  if (!rootStore.businessesStore?.selectedBusiness?.sortedChartOfAccounts) {
    accountsPromise = rootStore.businessesStore.getChartOfAccounts(rootStore.businessesStore.selectedBusinessId);
  } else {
    accountsPromise = Promise.resolve();
  }

  return (() => (
    Promise.allSettled([
      rootStore.businessesStore.fetchBankAccounts(rootStore.businessesStore.selectedBusinessId),
      rootStore.businessesStore.fetchBusinessConfig(rootStore.businessesStore.selectedBusinessId),
      rootStore.businessesStore.retrieveBusinessDefaultAccounts(rootStore.businessesStore.selectedBusinessId),
      accountsPromise,
    ]))
    .then(() => NiceModal.show('ClientSettingsModal', ModalProps))
  )();
};

export default ClientSettingsModal;
