import React, { useEffect, useState, useMemo } from 'react';
import { Checkbox, Tooltip } from 'antd';
import { PracticeTypes } from '@aider/constants-library';
import { SendOutlined } from '@ant-design/icons';
import _ from 'lodash';
import Markdown from 'markdown-to-jsx';
import draftToHtml from 'draftjs-to-html';
import NiceModal from '@ebay/nice-modal-react';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, Entity, Modifier, convertToRaw, convertFromRaw } from 'draft-js';
import { stateFromMarkdown } from 'draft-js-import-markdown';
import { stateToMarkdown } from 'draft-js-export-markdown';
import { useStore } from '../../../stores/Store';
import AssistantLoader from '../../AdvisoryAssistant/AssistantLoader';
import Notification from '../../Notification';
import AiderAlert from '../../AiderAlert';
import GenAI from '../../icons/GenAIWhite';
import ButtonPrimary from '../../customAntD/ButtonPrimary';
import ButtonSecondary from '../../customAntD/ButtonSecondary';
import { InfoRing } from '../../../components-v2/atoms/Icons';
import WysiwygVariableSelector from '../../reports/WysiwygVariableSelector';
import { trackMixpanelEvent } from '../../../lib/mixpanel';
import { customEntityTransform, focusEditorEnd } from '../../../lib/componentHelpers/reportHelpers';
import usePrevious from '../../../lib/customHooks/usePrevious';
import LLMAssistantHeader from './LLMAssistantHeader';
import { PromptType } from '../../../models/interfaces/components';

interface LLMAssistantContentProps {
  block: PracticeTypes.ReportBlock;
  type?: PromptType;
}

const variablePattern = /[#]{([^}]+)}/g;

const LLMAssistantContent = ({ block, type }: LLMAssistantContentProps) => {
  const rootStore = useStore();
  const { reportTemplateStore, templateTextStore } = rootStore;
  const [promptType, setPromptType] = useState<PromptType>(type);
  const prevType = usePrevious(promptType);
  const [promptHistory, setPromptHistory] = useState<string>('');

  const [editorState, setEditorState] = useState<EditorState>(EditorState.createEmpty());

  const [loading, setLoading] = useState(false);
  const [isSavePrompt, setIsSavePrompt] = useState(false);
  const [disablePromptSave, setDisablePromptSave] = useState(false);

  const savedPrompts = reportTemplateStore.selectedPerformanceReport?.settings[block.id]?.prompts;

  let editorRef;

  const setEditorRef = (ref) => {
    editorRef = ref;
  };

  React.useEffect(() => {
    focusEditorEnd(editorRef);
  }, [promptType]);

  useEffect(() => {
    // Need to check here if the prompt has been saved to the block settings
    const defaultPrompt = templateTextStore.getLLmPrompt(promptType);

    if (promptType !== prevType && defaultPrompt) {
      const newEmptyEditorState = EditorState.createEmpty();
      const newEditorState = reportTemplateStore.convertTemplateToDraftJS(newEmptyEditorState, defaultPrompt);
      setEditorState(newEditorState);
    } else if (savedPrompts?.length) {
      const newEditor = EditorState.createEmpty();
      const newEditorContent = reportTemplateStore.convertTemplateToDraftJS(newEditor, savedPrompts[0]);
      setEditorState(newEditorContent);
    }

    // if the prompt has been stored in the block settings, we want to set the save prompt checkbox to true and disable it
    if (savedPrompts?.length) {
      setIsSavePrompt(true);
      setDisablePromptSave(true);
    } else {
      setIsSavePrompt(promptType !== 'rewrite');
    }
  }, [promptType, reportTemplateStore.selectedPerformanceReport.settings[block.id]]);

  useEffect(() => {
    // This is needed to ensure that the correct spacing is applied to mentions when rendered in the markdown component
    const adjustMargins = () => {
      document.querySelectorAll('.wysiwyg-mention').forEach((mention, index, mentions) => {
        if (index > 0) {
          const prevSibling = mentions[index - 1].nextSibling;
          const mentionElement = mention as HTMLElement;
          if (prevSibling && prevSibling.nodeType === Node.TEXT_NODE && prevSibling.textContent.trim() !== '') {
            mentionElement.style.marginLeft = '0';
          } else {
            mentionElement.style.marginLeft = '1ch';
          }
        }
      });
    };

    adjustMargins();

    // Optionally, you can add a cleanup function or a dependency array
    // if you need to re-run this effect when certain dependencies change.
  }, [reportTemplateStore.updatedReportContent, promptType]);

  const insertVariable = (variable: string) => {
    trackMixpanelEvent({ description: 'Report Editor - Insert Variable', properties: { variable }, rootStore });
    const value = `#${variable}`;

    const currentState = editorState;
    const contentState = currentState.getCurrentContent();
    const entityKey = Entity.create('MENTION', 'IMMUTABLE', value);
    const modifiedContent = Modifier.replaceText(
      contentState,
      currentState.getSelection(),
      value,
      null,
      entityKey
    );

    editorRef.onChange(EditorState.push(editorState, modifiedContent, 'insert-fragment'));
  };

  const insights = reportTemplateStore.reportedInsights(block.id)?.join('\n');

  const hasBlockContent = useMemo(() => block.content && (block.content.blocks.length > 1 || block.content.blocks[0].text !== ''), [block.content]);

  const originalContent = useMemo(() => {
    const blockSettings = reportTemplateStore.selectedPerformanceReport?.settings[block.id];
    if (blockSettings?.prompts?.length && ['custom', 'rewrite'].includes(block.attributes?.type)) {
      // If there are prompts saved for the block, we want to set the orginal content from the settings
      return stateToMarkdown(convertFromRaw(blockSettings.originalContent[blockSettings.prompts.length - 1]));
    }

    switch (type) {
      case 'rewrite':
      case 'custom':
        return _.template(
          draftToHtml(block.content, null, null, customEntityTransform),
          { interpolate: variablePattern }
        )(reportTemplateStore.fallbackFormattedVariables);
      case 'summary':
      case 'action':
        return `Financial Performance:\n${insights}`;
      default:
        return '';
    }
  }, [type, insights, block.content, variablePattern, reportTemplateStore.fallbackFormattedVariables]);

  const blockContent = useMemo(() => {
    // If we have a situation where the is a saved prompt, we want to display the original content
    if (reportTemplateStore.selectedPerformanceReport?.settings[block.id]?.prompts?.length) {
      return originalContent;
    }

    return _.template(draftToHtml(block.content, null, null, customEntityTransform), { interpolate: variablePattern })(
      reportTemplateStore.fallbackFormattedVariables
    );
  }, [block.id, block.content, reportTemplateStore.fallbackFormattedVariables]);

  const cleanupConversation = () => {
    reportTemplateStore.editBlock = null;
    reportTemplateStore.clearReportContent();
    setEditorState(EditorState.createEmpty());
    NiceModal.hide('LLMAssistantModal');
  };

  const useGeneratedContent = () => {
    reportTemplateStore.setEditBlock(block);

    if (isSavePrompt && !disablePromptSave) {
      const blockRef = reportTemplateStore.selectedPerformanceReport.blocks?.find((b) => b.id === block.id);
      reportTemplateStore.storePromptToBlockSettings(promptHistory, block.id);
      const currentContent = convertToRaw(stateFromMarkdown(reportTemplateStore?.updatedReportContent));
      reportTemplateStore.storeOriginalContentToSettings(currentContent, block.id);

      if (blockRef) {
        blockRef.attributes = { ...blockRef.attribute, type: promptType };
      }
      // If the prompt was saved in the past and we have disabled the save prompt checkbox,
      // we want to notify that the changes are unasavable
      if (disablePromptSave) {
        reportTemplateStore.notificationList.push('unsavableChanges');
      } else {
        reportTemplateStore.notificationList.push('savableChanges');
      }
    }

    reportTemplateStore.useReportContent();

    if (['rewrite', 'custom'].includes(promptType) || promptHistory !== savedPrompts?.[0]) {
      const disableESAP = ['summary', 'action'].includes(block.attributes?.type);
      reportTemplateStore.updateSelectedReportWithSavedPromptBlocks({ disableCustom: true, disableESAP });
    }
    cleanupConversation();
  };

  const getLLMContent = (context) => {
    setLoading(true);
    const content = editorState.getCurrentContent().getPlainText();
    const prompt = _.template(
      content,
      { interpolate: variablePattern }
    )(reportTemplateStore.fallbackFormattedVariables);
    const useRag = type === 'custom';
    reportTemplateStore
      .updateLLMReportContent(prompt, context, useRag)
      .catch(() => {
        Notification({
          type: 'error',
          title: 'Something went wrong',
          description: 'Could not generate AI content. Please try again.',
        });
      })
      .finally(() => {
        setPromptHistory(content);
        setLoading(false);
      });
  };

  const submitPrompt = async () => {
    const context: any = { originalContent };
    if (reportTemplateStore?.updatedReportContent) {
      context.previousVersion = reportTemplateStore?.updatedReportContent;
    }

    getLLMContent(context);
  };

  const handleCheckBox = (e) => {
    setIsSavePrompt(e.target.checked);
  };

  const renderCheckbox = () => {
    if (promptType !== 'rewrite') {
      return (
        <div className='advisory-llm-modal__checkbox'>
          <Checkbox onChange={handleCheckBox} checked={isSavePrompt} disabled={disablePromptSave}>Save prompt to template</Checkbox>
          <Tooltip title={() => <div>When saving a template, only the instruction will be saved. The content will be regenerated each time you use the template.<br /><b>NOTE</b>: you can still make one-off edits to the content or the prompt, but the changes will not be saved to the template for reuse in the future.</div>} placement='bottomLeft'>
            <InfoRing width={20} height={20} />
          </Tooltip>
        </div>
      );
    }
    return null;
  };

  return editorState?.getCurrentContent() && (
    <>
      <LLMAssistantHeader {...{ promptType, setPromptType }} />
      <section className='advisory-llm-modal'>
        {
          (hasBlockContent) && (
            <AiderAlert title='Existing Text' type='info'>
              <ButtonSecondary className='advisory-llm-modal__button' onClick={cleanupConversation}>
                Keep Original Content
              </ButtonSecondary>
              <Markdown
                className='advisory-llm-modal__content scrollbar'
              >
                {blockContent}
              </Markdown>
            </AiderAlert>
          )
        }
        <AiderAlert title='AI Generated Text' type='info' icon={<GenAI />} ghost>
          {loading
            ? (
              <div className='place-center'>
                <AssistantLoader />
              </div>
            ) : (
              <>
                <ButtonPrimary className='advisory-llm-modal__button' onClick={useGeneratedContent} disabled={!reportTemplateStore?.updatedReportContent}>
                  Use generated content
                </ButtonPrimary>
                {renderCheckbox()}
                <Markdown className='advisory-llm-modal__content scrollbar'>
                  {reportTemplateStore?.updatedReportContent || 'Submit your prompt to get started.'}
                </Markdown>
              </>
            )}
        </AiderAlert>
      </section>
      <section className='advisory-llm-modal__footer'>
        {editorState?.getCurrentContent()?.size && reportTemplateStore?.fallbackFormattedVariables && (
          <Editor
            ref={setEditorRef}
            wrapperClassName='wysiwyg wysiwyg__minimal'
            editorClassName='wysiwyg__editor'
            toolbarClassName='wysiwyg__toolbar'
            toolbar={{
              options: ['history'],
              history: {
                inDropdown: false,
                className: undefined,
                component: undefined,
                dropdownClassName: undefined,
                options: ['undo', 'redo'],
              },
            }}
            toolbarCustomButtons={[<WysiwygVariableSelector mutator={insertVariable} />]}
            editorState={editorState}
            onEditorStateChange={setEditorState}
            customDecorators={[{
              strategy: (contentBlock, callback) => {
                contentBlock.findEntityRanges((character) => {
                  const entityKey = character.getEntity();
                  return entityKey !== null;
                }, callback);
              },
              component: ({ children }) => (
                <span className='rdw-mention-span'>{children}</span>
              )
            }]}
          />
        )}
        <ButtonPrimary
          type='primary'
          shape='circle'
          size='large'
          loading={loading}
          disabled={loading}
          icon={<SendOutlined />}
          onClick={submitPrompt}
        />
      </section>
    </>
  );
};

LLMAssistantContent.defaultProps = {
  type: 'custom',
};

export default LLMAssistantContent;
