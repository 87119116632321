import React from 'react';
import Cookies from 'js-cookie';
import { Collapse, CollapseProps } from 'antd';
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import CashFlowTips from './tips/CashFlowTips';

const InsightTips = ({ insightKey }) => {
  const cookieName = `showTips-${insightKey}`;
  const cookieTips = Cookies.get(cookieName);
  const expandedByDefault = typeof cookieTips === 'undefined' || cookieTips === 'true';
  const [items, setItems] = React.useState<CollapseProps['items']>([]);
  const toggleTips = (expandedIndexes: string | string[]) => {
    Cookies.set(cookieName, expandedIndexes.length ? 'true' : 'false', { expires: 365 });
  };

  React.useEffect(() => {
    let children;
    switch (insightKey) {
      case 'cashFlow':
        children = <CashFlowTips />;
        break;
      default:
        break;
    }

    if (children) {
      setItems([
        {
          key: '0',
          label: 'How to use this insight',
          children,
        },
      ]);
    }
  }, [insightKey]);

  return items?.length ? (
    <Collapse
      onChange={toggleTips}
      className='insight-tips'
      bordered={false}
      ghost
      expandIcon={({ isActive }) => (isActive ? <MinusCircleOutlined /> : <PlusCircleOutlined />)}
      items={items}
      defaultActiveKey={expandedByDefault ? '0' : undefined}
    />
  ) : null;
};

export default InsightTips;
