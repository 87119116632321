import React from 'react';
import { observer } from 'mobx-react';
import { SplitEnums } from '@aider/constants-library';
import { RightOutlined } from '@ant-design/icons';
import { useStore } from '../../stores/Store';
import AiderAlert from '../AiderAlert';
import ButtonPrimary from '../customAntD/ButtonPrimary';
import ButtonSecondary from '../customAntD/ButtonSecondary';
import Notification from '../Notification';
import { grantPermissionOnClick } from '../../lib/storeUtils';

const NoBudgetsBanner = () => {
  const { authenticationStore, businessesStore, insightStore, localeStore, resyncStore, splitStore } = useStore();
  const { selectedBusinessOsp, selectedBusinessShortCode } = businessesStore;
  const [buttonLoading, setButtonLoading] = React.useState(false);
  const dataStatus = resyncStore.selectedBusinessSnapshot?.etl?.tasks?.budgetEtl;
  const bannerKey = (
    dataStatus
    && dataStatus?.status === 'SKIP'
    && dataStatus?.error === 'missing-scope'
  ) ? 'warning' : 'error';
  let checkBudgetsUrl;
  let checkClickHandler;

  const bannerTitle = localeStore.translation(`insights.budgets.${bannerKey}.title`, {
    osp: localeStore.translation(`osps.${selectedBusinessOsp}`)
  });
  const bannerBody = localeStore.translation(`insights.budgets.${bannerKey}.body`, {
    osp: localeStore.translation(`osps.${selectedBusinessOsp}`)
  });
  const bannerBodyManual = localeStore.translation(`insights.budgets.${bannerKey}.body-manual`);
  const bannerLink = localeStore.translation(`insights.budgets.${bannerKey}.link`);
  const bannerImport = localeStore.translation(`insights.budgets.${bannerKey}.import`);
  const bannerCheck = localeStore.translation(`insights.budgets.${bannerKey}.check`, {
    osp: localeStore.translation(`osps.${selectedBusinessOsp}`)
  });

  if (selectedBusinessOsp === 'xero') {
    if (bannerKey === 'warning') {
      checkBudgetsUrl = undefined;
      checkClickHandler = async () => {
        setButtonLoading(true);
        await grantPermissionOnClick(authenticationStore);
      };
    } else {
      checkBudgetsUrl = `https://go.xero.com/organisationlogin/default.aspx?shortcode=${selectedBusinessShortCode}&redirecturl=/Reports/EditBudget.aspx`;
    }
  } else {
    checkBudgetsUrl = `https://${process.env.NODE_ENV === 'production' ? '' : 'sandbox.'}qbo.intuit.com/app/budgets`;
  }

  return (
    splitStore.featureEnabled(SplitEnums.FeatureFlags.Budget)
    && insightStore.isBudgetsActive
    && insightStore.availableBudgets.length === 0
    && (
      <AiderAlert
        className='insight-info-banner'
        type={bannerKey}
      >
        <section className='insight-info-banner__body'>
          <div className='insight-info-banner__body__copy'>
            {bannerTitle && (
              <p role='heading' aria-level={5} className='aider-alert__content--title'>
                {bannerTitle}
              </p>
            )}
            {bannerBody && <p>{bannerBody}</p>}
            {splitStore.featureEnabled(SplitEnums.FeatureFlags.ManualBudget)
            && bannerBodyManual
            && (
              <p>
                {bannerBodyManual}
                {' '}
                {bannerLink && (
                  <ButtonPrimary
                    size='small'
                    type='link'
                    className='no-padding'
                    icon={<RightOutlined />}
                    onClick={() => Notification({ type: 'info', title: 'Coming soon' })}
                    mixpanelEvent='Download Budget Template'
                  >
                    {bannerLink}
                  </ButtonPrimary>
                )}
              </p>
            )}
          </div>
          <aside className='insight-info-banner__body__actions'>
            {splitStore.featureEnabled(SplitEnums.FeatureFlags.ManualBudget)
            && bannerImport
            && (
              <ButtonSecondary
                size='middle'
                mixpanelEvent='Import Budget'
                onClick={() => Notification({ type: 'info', title: 'Coming soon' })}
              >
                {bannerImport}
              </ButtonSecondary>
            )}
            {bannerCheck && (
              <ButtonPrimary
                loading={buttonLoading}
                disabled={buttonLoading}
                className='btn-link'
                size='middle'
                href={checkBudgetsUrl}
                onClick={checkClickHandler}
                target='_blank'
                mixpanelEvent={bannerKey === 'warning' ? 'Grant Permission' : 'Check Budget'}
              >
                {bannerCheck}
              </ButtonPrimary>
            )}
          </aside>
        </section>
      </AiderAlert>
    )
  );
};

export default observer(NoBudgetsBanner);
