import * as React from 'react';
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import { HelpTitle, selectIndex } from '../../../entities/helpText';
import { AiderEarlyWarning } from '../../../components-v2/atoms/Icons';
import { EwsInsights } from '../../../models/enums/components';

type props = {
  confidence: string,
  insightKey: string,
}

const EarlyWarningBanner = (
  { confidence, insightKey }: props
) => {
  const rootStore = useStore();
  const { helpPanelStore } = rootStore;
  return EwsInsights?.[insightKey] && (
    <section
      role='banner'
      className='early-warning-banner'
      onClick={() => {
        helpPanelStore.setHelpItemVisible(HelpTitle.EWS);
        selectIndex(insightKey, rootStore);
      }}
    >
      <AiderEarlyWarning />
      <div className='early-warning-banner__detail'>
        <h4>
          Aider
          <span>Early Warning System</span>
        </h4>
        {confidence && (
          <p>
            Estimates confidence: <span>{confidence}</span>
          </p>
        )}
      </div>
    </section>
  );
};

export default observer(EarlyWarningBanner);
