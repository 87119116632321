import React from 'react';
import { CheckCircleFilled, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { PracticeTypes } from '@aider/constants-library';
import { observer } from 'mobx-react';
import ButtonSecondary from '../../customAntD/ButtonSecondary';
import { useStore } from '../../../stores/Store';

const GeneralActions = ({
  blockId,
  className
} : {
  blockId: PracticeTypes.ReportBlock['id'],
  className: string
}) => {
  const { reportTemplateStore } = useStore();
  const isEditing = reportTemplateStore.editBlock === blockId;

  return (
    <aside className={className}>
      {!isEditing && (
        <ButtonSecondary
          mixpanelEvent='Report Editor - Edit Block'
          className='borderless'
          icon={<EditOutlined />}
          onClick={() => { reportTemplateStore.editBlock = blockId; }}
        />
      )}
      {isEditing && (
        <>
          <ButtonSecondary
            mixpanelEvent='Report Editor - Delete Block'
            className='borderless'
            icon={<DeleteOutlined />}
            onClick={() => null}
          />
          <ButtonSecondary
            mixpanelEvent='Report Editor - Save Block'
            className='borderless save-button'
            icon={<CheckCircleFilled />}
            onClick={() => {
              reportTemplateStore.editBlock = null;
            }}
          />
        </>
      )}
    </aside>
  );
};

export default observer(GeneralActions);
