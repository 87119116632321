import React from 'react';

const CashFlowTips = () => (
  <ol className='insight-tips__list'>
    <li>
      <span className='insight-tips__list--header'>
        Be proactive:
      </span>
      Quickly find clients that Aider's Early Warning System thinks may{' '}
      have a cash flow problem.
    </li>
    <li>
      <span className='insight-tips__list--header'>
        Use this together with the other Aider Cash Flow insights:
      </span>
      This insight is NOT designed to be used on its own.{' '}
      If multiple cash flow insights indicate that the client struggles{' '}
      with cash flow management, then consider reaching out to the client{' '}
      and offer a full analysis using more advanced cash flow tools.
    </li>
    <li>
      <span className='insight-tips__list--header'>
        Generate sales:
      </span>
      Send this insight to your clients as a conversation starter and{' '}
      explain that you can provide this regularly as part of an increased{' '}
      service level.
    </li>
  </ol>
);

export default CashFlowTips;
