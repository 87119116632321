import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { QuestionOutlined, RightOutlined } from '@ant-design/icons';
import _ from 'lodash';
import { ClientManagementEnums } from '@aider/constants-library';
import Markdown from 'markdown-to-jsx';
import { HelpTitle, mapInsightToHelpKey, selectIndex } from '../../entities/helpText';
import { useStore } from '../../stores/Store';
import ButtonSecondary from '../customAntD/ButtonSecondary';
import EarlyWarningBanner from './EarlyWarning/EarlyWarningBanner';
import { LodashTemplateVariablePattern } from '../../models/constants/components';
import handleError from '../../lib/errorHandler';
import ButtonPrimary from '../customAntD/ButtonPrimary';

const InsightTrends = ({ insightKey, insightData }) => {
  const rootStore = useStore();
  const { actionStore, businessesStore, templateTextStore } = rootStore;
  const [trendText, setTrendText] = React.useState(null);

  useEffect(() => {
    try {
      const variables = templateTextStore.getTrendTextVariables(insightKey, insightData?.datapoints);
      const text = _.template(templateTextStore.getTrendTextTemplate(insightKey, 2, variables), {
        interpolate: LodashTemplateVariablePattern
      })(variables);
      setTrendText(text);
    } catch (error) {
      handleError({ error });
    }
  }, [insightKey, insightData]);

  return (
    <aside className='insight-container__details--trend'>
      <h4>
        Trends
        {mapInsightToHelpKey(insightKey) && (
          <ButtonSecondary
            size='small'
            shape='circle'
            className='insight-container__details--trend--help'
            icon={<QuestionOutlined />}
            onClick={() => selectIndex(insightKey, rootStore)}
          />
        )}
      </h4>

      <EarlyWarningBanner
        confidence={insightData?.datapoints?.estimateConfidence}
        insightKey={insightKey}
      />

      {trendText && (
        <div className='insight-container__details--trend--body'>
          <Markdown>
            {trendText}
          </Markdown>
        </div>
      )}

      {mapInsightToHelpKey(insightData.insightKey) && (
        <div>
          <ButtonPrimary
            className='no-padding'
            size='large'
            mixpanelEvent='Insight - How is this calculated'
            mixpanelProps={{ insightKey }}
            type='link'
            onClick={() => {
              rootStore.helpPanelStore.setHelpItemVisible(HelpTitle.CALCULATION);
              rootStore.helpPanelStore.setHelpItemVisible(HelpTitle.DATA);
              selectIndex(insightKey, rootStore);
            }}
            icon={<RightOutlined />}
          >
            How is this calculated
          </ButtonPrimary>
        </div>
      )}

      <footer>
        {businessesStore?.selectedBusinessOsp === ClientManagementEnums.OSPKeys.xero
        && businessesStore.selectedBusinessShortCode
        && (
          <ButtonSecondary
            size='large'
            mixpanelEvent='Insight - Go to Xero'
            mixpanelProps={{ insightKey }}
            href={insightKey === 'gst'
              ? `https://go.xero.com/organisationlogin/default.aspx?shortcode=${businessesStore.selectedBusinessShortCode}&redirecturl=/Bank/BankAccounts.aspx`
              : `https://reporting.xero.com/${businessesStore.selectedBusinessShortCode}/v2/Run/New/3003`}
          >
            Go to Xero
          </ButtonSecondary>
        )}
        {insightData?.shareItems?.length > 0 && (
          <ButtonPrimary
            size='large'
            mixpanelEvent='Insight - Share Insight'
            mixpanelProperties={{ insightKey }}
            onClick={() => {
              actionStore.setSubjectKey(insightData.insightKey, false);
              actionStore.selectInsightAsAction(insightData);
              actionStore.actionType = 'insight';
              actionStore.actionModalVisible = true;
            }}
          >
            Share Insight
          </ButtonPrimary>
        )}
      </footer>
    </aside>
  );
};

export default observer(InsightTrends);
