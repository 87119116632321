import React, { useCallback } from 'react';
import { observer } from 'mobx-react';
import { firestore } from 'firebase';
import { ClientManagementEnums } from '@aider/constants-library';
import InsightDataBreakdown from '../molecules/InsightDataBreakdown';
import PeriodStatus from '../molecules/PeriodStatus';
import { useStore } from '../../stores/Store';
import PeriodSelector from '../../components/dataCompliance/PeriodSelector';
import { ShimmerLoader } from '../../components/dataCompliance/ShimmerLoader';
import ComplianceChecklistMissing from '../../components/dataCompliance/ComplianceChecklistMissing';
import { useAuthenticatedEffect } from '../../lib/customHooks/useAuthenticatedEffect';
import { InsightTab } from '../../stores/v1/pageStore';

const CheckList = () => {
  const rootStore = useStore();
  const { checklistStore, businessesStore, localeStore, alertStore, practiceStore } = rootStore;
  const { selectedBusinessId } = businessesStore;
  const [firestoreSectionData, setFirestoreSectionData] = React.useState<{ id: string, lastUpdated: string }[]>([]);
  const checkListData = checklistStore.businessChecklists
    ?.get(selectedBusinessId)
    ?.get(checklistStore.activeChecklistType)?.tableGroups?.find(
      (tableGroup) => {
        if (checklistStore.activePeriod) return tableGroup.tableGroupPeriod.periodName === checklistStore.activePeriod;
        return tableGroup.tableGroupPeriod.periodName === checklistStore.defaultPeriod;
      }
    );

  React.useEffect(() => {
    let unsub = () => { };
    if (checkListData && checkListData.tableGroupId) {
      let query = firestore().collection('insightTableGroups').doc(checkListData.tableGroupId);
      if (checkListData.tableGroupId.includes('ARCHIVE')) {
        const tableGroupCollection = `${businessesStore.selectedBusinessId}-${checklistStore.activeChecklistType}`;
        query = firestore()
          .collection(`insightTableGroups/${tableGroupCollection}/archive`)
          .doc(checkListData.tableGroupId);
      }
      unsub = query.onSnapshot((querySnapshot) => {
        const res = querySnapshot.data()
          ?.categories.map((category) => (
            { id: category.categoryId, lastUpdated: category?.updatedAt?.toDate().toISOString() }
          ));
        if (res) setFirestoreSectionData(res);
      });
    }
    return () => {
      setFirestoreSectionData([]);
      unsub();
    };
  }, [businessesStore.selectedBusinessId, checkListData, checkListData?.tableGroupId]);

  const loadRules = useCallback(async (tableGroupId: string) => {
    if (!rootStore.rulesStore.selectedBusinessRules?.[tableGroupId]) {
      rootStore.rulesStore.retrieveRules(rootStore.businessesStore.selectedBusinessId, tableGroupId);
    }
  }, []);

  useAuthenticatedEffect(() => {
    checklistStore.retrieveChecklist(checklistStore.activeChecklistType);

    // Need the business chart of accounts for the accounts display
    businessesStore.getChartOfAccounts(businessesStore.selectedBusinessId);
  }, [businessesStore.selectedBusinessId, checklistStore.activeChecklistType]);

  if (checkListData) {
    loadRules(checkListData.tableGroupId);
    const alertCount = checkListData.alertCount.filter((guy) => guy.level < 0).length;
    alertStore.overwriteAlert(selectedBusinessId, InsightTab.dataCompliance, alertCount);
  }

  const usPractice = practiceStore?.countryCode?.toLowerCase() === 'us';
  const osp = businessesStore.selectedBusiness?.connectedOsps?.[0] || ClientManagementEnums.OSPKeys.xero;
  const qbBusiness = osp === ClientManagementEnums.OSPKeys.intuit;

  const shouldDisplaySection = (category) => {
    const isUsPracticeAndGst = usPractice && category.categoryId === 'gst';
    const isQbBusinessAndAccountAdmin = qbBusiness && category.categoryId === 'accountAdmin';
    return !(isUsPracticeAndGst || isQbBusinessAndAccountAdmin);
  };

  return (
    <section
      className='checklist-container'
    >
      <PeriodSelector />
      {checkListData ? (
        <>
          {checkListData.checklistStatus && (
            <PeriodStatus
              tableGroupId={checkListData.tableGroupId}
              editedBy={
                checkListData.checklistStatus[
                  checkListData.checklistStatus.length - 1
                ].editedBy
              }
              status={
                checkListData.checklistStatus[
                  checkListData.checklistStatus.length - 1
                ].status
              }
              text={
                checkListData.checklistStatus[
                  checkListData.checklistStatus.length - 1
                ].text
              }
              buttonText={
                checkListData.checklistStatus[
                  checkListData.checklistStatus.length - 1
                ].buttonText
              }
            />
          )}
          {checkListData.categories.filter(shouldDisplaySection).map((entry) => (
            <InsightDataBreakdown
              key={`InsightDataBreakdown__${entry.categoryId}`}
              title={localeStore.translation(`period-close.section-header.${entry.categoryId}`)}
              status={entry.status}
              data={entry.categoryItems.slice().sort((a, b) => {
                // No template, sort alphabetically
                if (!checklistStore.checklistSectionOrder?.[entry.categoryId]) return a.itemTitle.localeCompare(b.itemTitle);
                // Identify items position (or absence) in template array
                const aInx = checklistStore.checklistSectionOrder[entry.categoryId].indexOf(a.itemId);
                const bInx = checklistStore.checklistSectionOrder[entry.categoryId].indexOf(b.itemId);
                // Neither item is  one of our set order items, sort alphabetically
                if (aInx === -1 && bInx === -1) return a.itemTitle.localeCompare(b.itemTitle);
                // If one of the items is a set item but not the first one, first one moves second
                if (aInx === -1) return 1;
                // The first item is a set item and second is not, keep first one first
                if (bInx === -1) return -1;
                // Both are set items, place in order of template
                return aInx - bInx;
              })}
              actions={entry.actions}
              tableGroupId={checkListData.tableGroupId}
              categoryId={entry.categoryId}
              countryCode={rootStore.businessStore.selectedBusiness.countryCode}
              selectedPeriod={
                checklistStore.activePeriod || checklistStore.defaultPeriod
              }
              period={checkListData.tableGroupPeriod}
              lastUpdated={checkListData.lastRefreshedUtc || null}
              firestoreUpdatedAt={firestoreSectionData.find((section) => section.id === entry.categoryId)?.lastUpdated || null}
            />
          ))}
        </>
      ) : checklistStore.businessChecklists
        ?.get(selectedBusinessId)
        ?.get(checklistStore.activeChecklistType)?.error ? (
          <ComplianceChecklistMissing />
        ) : (
          <ShimmerLoader />
        )}
    </section>
  );
};

export default observer(CheckList);
