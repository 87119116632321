import { makeAutoObservable } from 'mobx';
import { ClientManagementTypes, ApiConstants } from '@aider/constants-library';
import type { RootStore } from './Store';
import { GET, POST } from '../lib/requests';
import handleError from '../lib/errorHandler';
import { ClientManagementDataType } from '../models/interfaces/stores';

export default class ClientManagementStore {
  rootStore: RootStore;

  dashboardRows: ClientManagementTypes.ClientManagementRow[];

  dashboardFilters: ClientManagementTypes.ClientManagementFilter;

  searchString: string = '';

  loading: boolean = false;

  additionalTags: string[] = [];

  get filteredRows(): ClientManagementDataType[] {
    return this.dashboardRows
      .filter((row) => row.name.toLowerCase().includes(this.searchString.toLowerCase()))
      .map((row) => ({ key: row.id, ...row }));
  }

  async getClientManagementDashboard(options: { useGlobalLoader: boolean } = { useGlobalLoader: true }) {
    if (!this.loading) {
      this.loading = true;
      const url = `${ApiConstants.apiEndpointsBase.business}/users/${this.rootStore.userStore.id}/clientManagementDashboard`;
      if (options.useGlobalLoader) {
        this.rootStore.loadingStore.setLoading('clientManagement');
      }
      GET({ url, rootStore: this.rootStore })
        .then((res: ClientManagementTypes.ClientManagementResponse) => {
          if (!res.rows || !res.filters) {
            throw Error('Required Data not retrieved');
          }
          this.dashboardRows = res.rows;
          this.dashboardFilters = res.filters;
        })
        .catch((error) => {
          handleError({ error });
        })
        .finally(() => {
          this.rootStore.loadingStore.setDoneLoading('clientManagement');
          this.loading = false;
        });
    }
  }

  async addTagsToBusinesses() {
    const businessIds = this.rootStore.businessesStore.selectedForActivation;

    const url = `${ApiConstants.apiEndpointsBase.business}/practices/${this.rootStore.businessStore.practiceId.id}/tags/bulk`;
    const tagsUrl = `${ApiConstants.apiEndpointsBase.business}/practices/${this.rootStore.businessStore.practiceId.id}/tags`;

    try {
      const businessResponse = await POST({ url, data: { businessIds, tags: this.additionalTags }, rootStore: this.rootStore });
      const successful = businessResponse.length === businessIds.length;

      businessResponse.forEach((bus) => {
        const dashboardRowRef = this.dashboardRows.find((row) => row.id === bus.businessId);
        dashboardRowRef.tags = bus.tags;
      });

      const { tags = [] } = await GET({ url: tagsUrl, rootStore: this.rootStore });
      this.dashboardFilters.tags = tags.map((tag) => ({ value: tag, text: tag }));

      return { successful };
    } catch (error) {
      handleError({ error });
      throw Error('Failed to add tags');
    }
  }

  setAddtionalTags(tags: string[]) {
    this.additionalTags = tags;
  }

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(
      this,
      {
        rootStore: false,
      },
      { autoBind: true }
    );
  }
}
