import React from 'react';
import { observer } from 'mobx-react';
import Markdown from 'markdown-to-jsx';
import { ValueTypes } from '@aider/aider-formatting-library';
import { AppstoreOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { useStore } from '../../stores/Store';
import TrendArrow from './TrendArrow';

const InsightSummary = ({ insightKey, summary }) => {
  const { localeStore } = useStore();
  const [formattedDatapoints, setFormattedDatapoints] = React.useState(null);

  React.useEffect(() => {
    if (summary?.datapointsV2) {
      const processedDatapoints = {};
      Object.keys(summary?.datapointsV2).forEach((datapoint) => {
        if (!summary?.datapointFormats?.[datapoint]) {
          processedDatapoints[datapoint] = summary.datapointsV2[datapoint];
        } else {
          processedDatapoints[datapoint] = localeStore.formatDate(
            summary.datapointsV2[datapoint],
            summary.datapointFormats[datapoint],
          );
        }
      });
      setFormattedDatapoints(processedDatapoints);
    }
  }, [summary?.datapoints, summary?.datapointFormats]);

  return (
    <aside className={`insight-summaries__summary insight-summaries__summary__${summary?.status}`}>
      <h4 className='insight-summaries__summary--title'>
        {formattedDatapoints && (
          <Markdown>
            {localeStore.translation(
              `insights.${insightKey}.summaries.${summary.summaryKey}`,
              formattedDatapoints
            )}
          </Markdown>
        )}
      </h4>
      <p className='insight-summaries__summary--value'>
        {localeStore.localisedCurrency(summary?.value)}
      </p>
      <p className='insight-summaries__summary--trend'>
        <TrendArrow changePercentage={summary?.changePercentage} />{localeStore.format.formatValue({ value: Math.abs(summary?.changePercentage), format: ValueTypes.percentage })}
      </p>
      {summary?.shownOnDashboard && (
        <Tooltip title='Shown on the Dashboard' placement='bottom'>
          <AppstoreOutlined className='insight-summaries__summary--dashboardIcon' />
        </Tooltip>
      )}

    </aside>
  );
};

export default observer(InsightSummary);
