import React from 'react';
import { Checkbox } from 'antd';
import { PeriodCloseTypes } from '@aider/constants-library';
import { useStore } from '../../../stores/Store';
import { Urgency } from '../../../ts/enums/Constants';
import { CheckAlert, ExclamationAlert } from '../../atoms/Icons';
import { State } from '../../../ts/enums/Colors';
import { trackMixpanelEvent } from '../../../lib/mixpanel';

const CheckListOptions = ({
  checklistIdentifier,
  title
}: {
  checklistIdentifier: PeriodCloseTypes.ChecklistItem['checklistIdentifier'],
  title: PeriodCloseTypes.ChecklistItem['title'],
}) => {
  const rootStore = useStore();
  const { checklistStore } = rootStore;

  const getItemData = () => (checklistStore.currentChecklistGroups.tableGroups.find(
    (tableGroup) => tableGroup.tableGroupId === checklistIdentifier.checklistId
  )?.categories?.find(
    (section) => section.categoryId === checklistIdentifier.sectionId
  )?.categoryItems?.find(
    (item) => item.itemId === checklistIdentifier.itemId
  ));

  const [saving, setSaving] = React.useState(false);
  const [checkBox, setCheckBox] = React.useState(getItemData()?.checkBox);
  const [status, setStatus] = React.useState(Urgency.success);

  React.useEffect(() => {
    const itemData = getItemData();

    if (itemData) {
      // Rerender when checklist checkbox changes
      setCheckBox(itemData.checkBox);
      setStatus(itemData.status);
    }
  }, [checklistStore.currentChecklistGroups]);

  const onCheckBoxChange = async () => {
    const { checklistId, sectionId, itemId } = checklistIdentifier;
    setSaving(true);
    const newCheckBoxState = checkBox.state === 'checked'
      ? {
        ...checkBox,
        state: 'unchecked'
      }
      : {
        ...checkBox,
        state: 'checked'
      };

    setCheckBox(newCheckBoxState);
    trackMixpanelEvent({ description: 'Period Close Rule Checked', properties: { ruleName: title, checklistId, sectionId, itemId, status: newCheckBoxState?.state }, rootStore });
    checklistStore.toggleCheckListCheck(checklistId, sectionId, itemId); // Not awaiting this to keep the UI responsive
    setSaving(false);
  };

  const getStatusIcon = () => {
    switch (status) {
      case Urgency.danger:
        return (
          <ExclamationAlert
            className='compliance-checks__icon'
          />
        );
      case Urgency.success:
        return (
          <CheckAlert
            className='compliance-checks__icon'
            fill={State.success}
          />
        );
      case Urgency.muted:
        return (
          <ExclamationAlert
            className='compliance-checks__icon'
            fill={State.disabled}
          />
        );
      default:
        // warning
        return (
          <CheckAlert
            className='compliance-checks__icon'
            fill={State.warning}
          />
        );
    }
  };

  return (
    <>
      {(checklistIdentifier.type !== 'MANUAL_CHECK') && getStatusIcon()}
      <Checkbox
        onClick={(e) => e.stopPropagation()}
        className='compliance-checks'
        disabled={saving}
        checked={checkBox?.state === 'checked'}
        onChange={onCheckBoxChange}
      />
    </>
  );
};

export default CheckListOptions;
