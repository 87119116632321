import React from 'react';
import Markdown from 'markdown-to-jsx';
import { RightOutlined } from '@ant-design/icons';
import { useStore } from '../../stores/Store';
import AlertIllustration from '../illustrations/AlertIllustration';
import ButtonPrimary from '../customAntD/ButtonPrimary';

type Props = {
  insightKey: string;
  error?: any;
  helpUrl?: string;
  helpLabel?: string;
};

const MissingInsight = ({ insightKey, error, helpUrl, helpLabel }: Props) => {
  const { businessesStore, localeStore } = useStore();
  const describedErrorKeys = ['gst-basis', 'gst-sales-period', 'gst-report-period', 'gst-country', 'cashflow-bank-accounts', 'cashflow-data'];
  const insightTitle = localeStore.translation(`insights.${insightKey}.title`);
  return (
    <section className='insight-container insight-container__missing'>
      <h3>
        {insightTitle}
      </h3>
      <div className='insight-container__missing__content'>
        <div className='insight-container__missing__illustration'>
          <AlertIllustration />
        </div>
        <h4>{localeStore.translation('insights.missing.title', { insightTitle })}</h4>
        <div className='insight-container__missing__body'>
          <Markdown>
            {error?.code && describedErrorKeys.includes(error.code)
              ? localeStore.translation(`insights.errors.${error.code}`, { osp: businessesStore.selectedBusinessOspName })
              : localeStore.translation('insights.errors.default', { osp: businessesStore.selectedBusinessOspName })}
          </Markdown>
        </div>
        <div className='insight-container__missing__body'>
          <Markdown>
            {localeStore.translation('insights.missing.description')}
          </Markdown>
        </div>
        <ButtonPrimary
          className='no-padding'
          size='large'
          type='link'
          href={helpUrl}
          target='_blank'
          icon={<RightOutlined />}
        >
          {helpLabel}
        </ButtonPrimary>
      </div>
    </section>
  );
};

MissingInsight.defaultProps = {
  error: null,
  helpUrl: 'https://ask.aider.ai/help/troubleshooting-advisory-insights-not-available',
  helpLabel: 'Help & Support - Insight Troubleshooting Guide'
};

export default MissingInsight;
