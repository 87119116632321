import React from 'react';
import _ from 'lodash';
import { CheckCircleFilled, EditOutlined } from '@ant-design/icons';
import { PracticeTypes } from '@aider/constants-library';
import { observer } from 'mobx-react';
import { Dropdown, Tooltip } from 'antd';
import type { MenuProps } from 'antd';
import ButtonSecondary from '../../customAntD/ButtonSecondary';
import { useStore } from '../../../stores/Store';
import GenAI from '../../icons/GenAI';
import LLMAssistantModal from '../../modals/LLMAssistantModal';
import { PromptType } from '../../../models/interfaces/components';
import Notification from '../../Notification';

const TextActions = ({
  block,
  className
}: {
  block: PracticeTypes.ReportBlock,
  className: string
}) => {
  const { reportTemplateStore } = useStore();
  const isEditing = reportTemplateStore.editBlock === block.id;

  const modalData = [
    {
      subtitle: 'Rewrite',
      text: 'Rewrite',
      type: 'rewrite'
    },
    {
      subtitle: 'Executive Summary',
      text: 'Executive Summary',
      type: 'summary'
    },
    {
      subtitle: 'Action Points',
      text: 'Action Points',
      type: 'action'
    },
    {
      subtitle: 'Custom detail & data',
      text: 'Custom detail & data',
      type: 'custom'
    }
  ];

  const items: MenuProps['items'] = [
    {
      key: '1',
      type: 'group',
      label: 'Select AI assistant...',
      children: modalData.map(({ text, type }) => ({
        key: text,
        label: (
          <a
            onClick={() => {
              LLMAssistantModal(
                {
                  block,
                  type: type as PromptType,
                  reportTemplateStore,
                }
              );
            }}
          >
            {text}
          </a>
        )
      }))
    }
  ];

  return (
    <aside className={className}>
      {!isEditing && (
        <>
          <Dropdown
            placement='bottomRight'
            className='genai-button__dropdown'
            overlayClassName='genai-button__dropdown'
            menu={{ items }}
            arrow
          >
            <ButtonSecondary
              className='borderless'
              icon={<GenAI />}
              mixpanelEvent='Report Editor - Gen AI'
            />
          </Dropdown>
          <Tooltip
            title='Manual Edit'
            placement='bottom'
          >
            <ButtonSecondary
              className='borderless'
              icon={<EditOutlined />}
              mixpanelEvent='Report Editor - Edit Text'
              mixpanelProperties={{ editBy: 'Action Button' }}
              onClick={() => {
                // Set the current block content state to the store adn the edit block id
                reportTemplateStore.setEditBlock(block);
              }}
            />
          </Tooltip>
        </>
      )}
      {isEditing && (
      <Tooltip
        title='Save'
        placement='bottom'
      >
        <ButtonSecondary
          className='borderless save-button'
          icon={<CheckCircleFilled />}
          mixpanelEvent='Report Editor - Save Text'
          onClick={() => {
            const startBlockContent = reportTemplateStore.blockEditingState.get(block.id);
            // if there is actually a change in the text then there have been changes
            if (!_.isEqual(startBlockContent, block.content)) {
              if (block.type === 'text') {
                const disableESAP = ['summary', 'action'].includes(block?.attributes?.type);
                reportTemplateStore.updateSelectedReportWithSavedPromptBlocks({ disableCustom: true, disableESAP });
                if (reportTemplateStore.hasMissingVariabledUsedInBlock(block)) {
                  Notification({
                    type: 'warning',
                    title: 'Some data may be missing',
                    description: 'You may want to choose a different template OR make one-off edits to hide the insights with missing data. Look for “N/A” in this report.',
                    duration: 10
                  });
                }
              }
              // if we have prompts stored in the settings for the block
              if (reportTemplateStore.editedPerformanceReportTemplate?.settings?.[block.id]?.prompts?.length > 0) {
                // then we set the notification that there are changes that can't be saved
                reportTemplateStore.notificationList.push('unsavableChanges');
              } else {
                // otherwise the changes are savable
                reportTemplateStore.notificationList.push('savableChanges');
              }
            }

            reportTemplateStore.clearEditBlock();
          }}
        />
      </Tooltip>
      )}
    </aside>
  );
};

export default observer(TextActions);
