import React from 'react';
import { observer } from 'mobx-react';
import { Form } from 'antd';
import AccountsTreeSelect from '../../form/AccountsTreeSelect';
import { useStore } from '../../../stores/Store';

/**
 * @deprecated This will be removed after the feature flag is removed
 * @param param0
 * @returns
 */
const PayrollAccountsModalContent = ({ modalType, form }: { modalType?: string, form: any }) => {
  const { localeStore } = useStore();

  const accountTypeMap = {
    wagesComparison: 'P&L Wages',
    payeComparison: 'PAYE Balance Sheet',
    superPaymentVsPayroll: 'Superannuation Payments',
    payePaymentVsPayroll: 'PAYE Payments',
    superBalanceVsPayroll: localeStore.translation('period-close.super-balance.account-modal-field-label'),
  };

  const label = !modalType ? null : `${accountTypeMap[modalType] ? accountTypeMap[modalType] : 'Relevant'} Accounts`;
  return (
    <Form
      form={form}
      layout='vertical'
      name='rule-builder'
      wrapperCol={{ span: 19 }}
      initialValues={{ remember: true }}
      autoComplete='off'
      className='rule-form'
      requiredMark={false}
    >
      <AccountsTreeSelect
        label={label}
        sectionId={modalType}
        treeCheckable
      />

    </Form>
  );
};

PayrollAccountsModalContent.defaultProps = {
  modalType: null,
};

export default observer(PayrollAccountsModalContent);
