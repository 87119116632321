import React, { useCallback, useEffect } from 'react';
import { Form, TreeSelect } from 'antd';
import { observer } from 'mobx-react';
import { DownOutlined } from '@ant-design/icons';
import { CustomRuleEnums } from '@aider/constants-library';
import { useStore } from '../../stores/Store';
import { ITreeValue } from '../../models/interfaces/components';
import { findAndMarkItems, fromOptionsTree, getExpandedKeys } from '../../lib/componentHelpers/accountFormHelpers';
import { AccountsTreeData } from '../../ts/interfaces/molecules/DataComplianceRules';
import { usePrevious } from '../../entities/utils';

const AccountsTreeSelect = ({
  label,
  sectionId,
  treeCheckable,
  rule,
  keywordsPrefix,
  disabled,
  businessId,
  placeholder,
  osp,
  dataId,
  required,
}: {
  label?: string,
  sectionId: string | CustomRuleEnums.RuleCategories,
  treeCheckable: boolean,
  rule?: any,
  keywordsPrefix?: any,
  disabled?: boolean,
  businessId?: string,
  placeholder?: string,
  osp?: string,
  dataId?: string,
  required?: boolean
}) => {
  const { businessesStore, practiceStore } = useStore();
  const [selectedFrom, setSelectedFrom] = React.useState<string | ITreeValue[]>(treeCheckable ? [] : '');
  const [fromOptions, setFromOptions] = React.useState<AccountsTreeData[]>([]);
  const [loadLimiter, setLoadLimiter] = React.useState<boolean>(false);
  const form = Form.useFormInstance();
  const prevTreeCheckable = usePrevious(treeCheckable);
  const balanceSheetSections = ['superBalanceSheetAccounts', 'wagesBalanceSheetAccounts', 'paygBalanceSheetAccounts'];
  const profitAndLossSections = ['superProfitAndLossAccounts', 'wagesProfitAndLossAccounts'];
  const activeSection = balanceSheetSections.includes(sectionId)
    ? CustomRuleEnums.RuleCategories.balanceSheet
    : (profitAndLossSections.includes(sectionId)
      ? CustomRuleEnums.RuleCategories.profitAndLoss
      : sectionId);

  const updateSelection = (value) => {
    const key = dataId || (osp ? `from-${osp}` : 'from');
    form.setFieldsValue({ [key]: value });
    setSelectedFrom(value);
  };

  let sortedChart;
  // check if it is a practice
  if (businessId === practiceStore.id) {
    sortedChart = businessesStore.businesses?.get(businessId).sortedChartOfAccounts[osp]?.[activeSection];
  } else {
    sortedChart = businessId
      ? businessesStore.businesses?.get(businessId)?.sortedChartOfAccounts?.[activeSection]
      : businessesStore?.selectedBusiness?.sortedChartOfAccounts?.[activeSection];
  }

  const setSelectedItems = useCallback(() => {
    if (!fromOptions) return;
    if (treeCheckable) {
      let initialValue = [];

      if (rule && !(rule?.rule?.from?.length === 1
        && (rule?.rule.from[0] === null
          || rule?.rule.from[0].includes(keywordsPrefix))
      )) {
        initialValue = rule.rule.from;
      }

      const supportedSections = [
        'wagesProfitAndLossAccounts',
        'wagesBalanceSheetAccounts',
        'paygBalanceSheetAccounts',
        'superProfitAndLossAccounts',
        'superBalanceSheetAccounts',
        'revenueAccounts',
        'directCostsAccounts',
        'opexAccounts',
        'superBalanceVsPayroll',
        'superPaymentVsPayroll',
        'payePaymentVsPayroll',
        'incomeTaxAccounts'
      ];

      if (supportedSections.includes(sectionId)) {
        initialValue = businessesStore.selectedBusinessConfig?.overrides?.[sectionId]
          ? businessesStore.selectedBusinessConfig.overrides[sectionId]
          : businessesStore.selectedBusiness?.defaultAccounts?.[sectionId]
          ?? [];
      }

      findAndMarkItems(
        fromOptions,
        selectedFrom,
        initialValue.map((val) => ({
          label: val,
          value: val,
        })),
        activeSection,
        sortedChart,
        updateSelection
      );
    } else if (rule && rule?.rule?.from) {
      updateSelection(Array.isArray(rule.rule.from) ? rule.rule.from[0] : rule.rule.from);
    }
  }, [rule, treeCheckable, fromOptions]);

  useEffect(() => {
    // Obtain initial values selected for from dropdown on load once accounts
    // are loaded
    if (fromOptions.length && !loadLimiter) {
      setSelectedItems();
      setLoadLimiter(true);
    }
  }, [fromOptions, rule]);

  useEffect(() => {
    // Get possible accounts for from dropdown on load
    const opts = fromOptionsTree(selectedFrom, activeSection, sortedChart, !treeCheckable);
    setFromOptions(opts);
  }, [selectedFrom, activeSection, businessesStore.selectedBusiness, !treeCheckable]);

  useEffect(() => {
    // Reset selection when changing selection method
    if (prevTreeCheckable !== undefined && prevTreeCheckable !== treeCheckable) {
      updateSelection(treeCheckable ? [] : '');
    }
  }, [treeCheckable]);

  const setPlaceholder = (placeholderString: string) => {
    if (placeholderString) return placeholderString;

    return ['wagesComparison', 'payeComparison'].indexOf(sectionId) > -1
      ? 'Select all applicable accounts'
      : undefined;
  };

  return (
    <Form.Item
      name={dataId || (osp ? `from-${osp}` : 'from')}
      className='accounts-tree-selector'
      label={label}
      rules={[
        {
          required: required ?? !disabled,
          message: 'Please select at least one account',
        },
      ]}
    >
      <TreeSelect
        onChange={(values) => findAndMarkItems(
          fromOptions,
          selectedFrom,
          values,
          activeSection,
          sortedChart,
          updateSelection
        )}
        popupClassName='ant-tree-dropdown aider-rule-dropdown'
        showArrow
        allowClear
        size='large'
        multiple={treeCheckable}
        maxTagCount='responsive'
        treeData={fromOptions}
        treeCheckable={treeCheckable}
        treeDefaultExpandedKeys={treeCheckable ? getExpandedKeys(fromOptions) : null}
        treeCheckStrictly={treeCheckable}
        showCheckedStrategy={!treeCheckable ? 'SHOW_CHILD' : 'SHOW_PARENT'}
        showSearch
        treeNodeFilterProp='title'
        switcherIcon={<DownOutlined />}
        disabled={disabled}
        treeDefaultExpandAll={!treeCheckable}
        placeholder={setPlaceholder(placeholder)}
      />
    </Form.Item>
  );
};

AccountsTreeSelect.defaultProps = {
  label: null,
  rule: null,
  keywordsPrefix: null,
  disabled: false,
  businessId: undefined,
  placeholder: null,
  osp: null,
  dataId: null,
  required: null,
};

export default observer(AccountsTreeSelect);
