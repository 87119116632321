import React, { useState, useEffect, useRef } from 'react';
import { observer } from 'mobx-react';
import {
  QuestionCircleOutlined,
  ToolOutlined,
  LogoutOutlined,
  AppstoreOutlined,
  LineChartOutlined,
} from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import { BusinessActivationStatus } from '@aider/constants-library/dist/enums/clientManagement';
import { useStore, rootStore } from '../stores/Store';
import NavLinkCustom from './NavLinkCustom';
import LogoNavIcon from './icons/LogoNavIcon';
import { Routers } from '../models/enums/utils';
import { trackMixpanelEvent } from '../lib/mixpanel';

import EarlyAccessFlag from './icons/EarlyAccessFlag';
import PracticeInitialsLogo from './PracticeInitialsLogo';
import NavIconClientManagement from './icons/NavIconClientManagement';
import GenAIWhite from './icons/GenAIWhite';
import TextPill from './TextPill';
import { NavIconPeriodCloseDashboard } from './icons';

/**
 * Component: NavBar
 * The navigation bar component for the application
 *
 * @returns A navigation bar component with various navigation links and dropdown menu
 */
const NavBar: React.FC = () => {
  const { businessesStore, periodCloseDashboardStore, dashboardStore, practiceStore } = useStore();
  const { practice } = practiceStore;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [accountTooltipOpen, setAccountTooltipOpen] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  // Event handler for detecting clicks outside the dropdown menu
  const handleDropdownOutsideClick = (e: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target as Node)) {
      setIsOpen(false);
    }
  };

  // Event handler for the dropdown button click
  const handleButtonClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setIsOpen(!isOpen);
    setAccountTooltipOpen(false);
  };

  useEffect(() => {
    document.addEventListener('click', handleDropdownOutsideClick);
    return () => {
      document.removeEventListener('click', handleDropdownOutsideClick);
    };
  }, []);

  // TODO: To remove this when auth refactor is done
  const handleSignOutClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setIsOpen(false);
    trackMixpanelEvent({
      description: 'Sign Out clicked',
      properties: { NavBar: 'Sign Out' },
      rootStore,
    });

    rootStore.authStore.handleSignOut();
  };

  /**
   * In order to display a pill on a nav link using existing styles use the
   * following format label for the `NavLinkCustom` component
   * label={
   *  (
   *    <span className='navBar-main__icon--wrapper'>
   *      <IconComponent />
   *      <TextPill size='small'>NEW</TextPill>
   *    </span>
   *  )
   * }
   */
  return (
    <nav className='navBar navBar-main'>
      <EarlyAccessFlag className='navBar-main__earlyAccessFlag' />
      <LogoNavIcon className='navBar-main__logo' />
      <div className='navBar-main__top'>
        <NavLinkCustom
          to={Routers.DASHBOARD}
          label={<AppstoreOutlined />}
          tooltipTitle='Dashboard'
          className='navBar-main__icon'
          trackPath='Dashboard'
          onSelect={() => {
            dashboardStore.loadDashboard();
            businessesStore.fetchBusinessData({
              businessStatus: BusinessActivationStatus.activated,
            });
          }}
          aria-label='dashboard'
        />

        <NavLinkCustom
          to={Routers.PERIOD_CLOSE_DASHBOARD}
          label={(
            <span className='navBar-main__icon--wrapper'>
              <NavIconPeriodCloseDashboard />
              <TextPill size='small'>NEW</TextPill>
            </span>
          )}
          tooltipTitle='Period Close Dashboard'
          className='navBar-main__icon'
          trackPath='Period Close Dashboard'
          aria-label='Period Close Dashboard'
          onSelect={() => {
            periodCloseDashboardStore.retrieveDashboardDebounced();
          }}
        />

        <NavLinkCustom
          to={Routers.INSIGHTS}
          label={<LineChartOutlined />}
          tooltipTitle='Client Insights'
          className='navBar-main__icon'
          trackPath='Client Insights'
          aria-label='insights'
          onSelect={() => {
            businessesStore.fetchBusinessData({
              businessStatus: BusinessActivationStatus.activated,
              selectBusiness: true
            });
          }}
        />

        <NavLinkCustom
          to={Routers.ADVISORY_ASSISTANT}
          label={<GenAIWhite />}
          tooltipTitle='AI Assistant'
          className='navBar-main__icon'
          trackPath='AI Assistant'
          aria-label='AI Assistant'
        />

        <NavLinkCustom
          to={Routers.CLIENTS_MANAGEMENT}
          label={<NavIconClientManagement />}
          tooltipTitle='Client Management'
          className='navBar-main__icon'
          trackPath='Loading Zone'
          aria-label='client management'
          onSelect={() => {
            businessesStore.fetchBusinessData({ useLoader: 'clientManagement' });
          }}
        />
      </div>

      <div className='navBar-main__bottom'>
        <NavLinkCustom
          to={Routers.HELP}
          label={<QuestionCircleOutlined />}
          tooltipTitle='Help'
          className='navBar-main__icon'
          trackPath='Help'
          openInNewTab
        />

        {/* TODO: Waiting auth refactor  */}
        <div
          className='navBar-main__dropdown'
          ref={dropdownRef}
        >
          <Tooltip
            placement='rightTop'
            title='Account Settings'
            open={accountTooltipOpen}
            onOpenChange={setAccountTooltipOpen}
          >
            <Button
              size='large'
              icon={
                practice.brandingLogo ? (
                  <img
                    className='navBar-main__dropdown-img'
                    src={practice.brandingLogo}
                    alt='branding log'
                  />
                ) : (
                  <PracticeInitialsLogo />
                )
              }
              onClick={handleButtonClick}
            />
          </Tooltip>
          <div
            className={`navBar-main__dropdown-list ${
              !isOpen ? 'navBar-main__dropdown-list--hide' : ''
            }`}
            role='menu'
            aria-hidden={!isOpen}
          >
            <NavLinkCustom
              to={Routers.SETTINGS}
              label={(
                <>
                  <ToolOutlined /> Account Settings
                </>
              )}
              className='navBar-main__dropdown-item'
              onSelect={() => setIsOpen(false)}
              trackPath='Account Settings'
            />
            {/* TODO: To refactor the auth and change the node then */}
            <div
              className='navBar-main__dropdown-item'
              onClick={handleSignOutClick}
            >
              <LogoutOutlined /> Sign out
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default observer(NavBar);
