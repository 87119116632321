import { observer } from 'mobx-react';
import React from 'react';
import { ClientManagementEnums } from '@aider/constants-library';
import { CheckCircleFilled, ExclamationCircleFilled } from '@ant-design/icons';
import { DateFormats } from '@aider/aider-formatting-library';
import { useStore } from '../../stores/Store';

const ReconciliationStatusBanner = ({ insightData }) => {
  const { businessesStore, localeStore } = useStore();

  const { periodData, reconciliationStatus, summaries } = insightData;

  let Icon: any;
  let ospLink: string;
  const clientCode = businessesStore.selectedBusiness?.connectedApps?.[0]?.configuration?.entities?.[0]?.shortCode;

  switch (businessesStore.selectedBusinessOsp) {
    case ClientManagementEnums.OSPKeys.xero:
      ospLink = `https://go.xero.com/organisationlogin/default.aspx?shortcode=${
        clientCode
      }&redirecturl=/Bank/BankAccounts.aspx`;
      break;
    case ClientManagementEnums.OSPKeys.intuit:
      break;
    default:
  }

  switch (reconciliationStatus?.status) {
    case 'danger':
      Icon = ExclamationCircleFilled;
      break;
    case 'success':
      Icon = CheckCircleFilled;
      break;
    default:
  }

  let monthName = localeStore.formatDate(periodData?.end, DateFormats.fullMonth);
  if (periodData?.type === 'quarterly') {
    const { datapointsV2 } = summaries?.[0];
    monthName = localeStore.translation('insights.dataHealthStatus.quarter', {
      quarter: datapointsV2?.quarter,
      year: datapointsV2?.year,
    });
  }
  const formattedDate = localeStore.formatDate(reconciliationStatus?.reconciledToDate, DateFormats.string);
  return reconciliationStatus && (
    <div className='reconciliation-status'>
      <Icon />
      <span className='reconciliation-status__status'>
        { localeStore.translation(`insights.dataHealthStatus.header.${reconciliationStatus?.status !== 'danger' ? 'complete' : 'incomplete'}`, { month: monthName }) }
      </span>

      {formattedDate && (
        localeStore.translation('insights.dataHealthStatus.reconciled', { date: formattedDate })
      )}

      {reconciliationStatus?.unreconciledCount && (
        localeStore.translation('insights.dataHealthStatus.unreconciled', { count: reconciliationStatus?.unreconciledCount })
      )}

      {reconciliationStatus?.unreconciledCount && reconciliationStatus?.status === 'danger' && (
        <>{' - '}</>
      )}

      {reconciliationStatus?.status === 'danger' && ospLink && (
        <a href={ospLink} target='_blank' rel='noreferrer'>
          {
            localeStore.translation('insights.dataHealthStatus.link', {
              osp: localeStore.translation(`osps.${businessesStore.selectedBusiness?.osp || ClientManagementEnums.OSPKeys.xero}`),
            })
          }
        </a>
      )}
    </div>
  );
};

export default observer(ReconciliationStatusBanner);
