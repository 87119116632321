import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useStore } from '../stores/Store';
import queryStringInspector from '../lib/queryStringInspector';
import TimeoutProvider from '../utils/TimeoutProvider';

const OpenRoute = () => {
  const rootStore = useStore();
  const navigate = useNavigate();

  React.useEffect(() => {
    queryStringInspector(rootStore, navigate);
  }, []);

  return (
    <TimeoutProvider>
      <Outlet />
    </TimeoutProvider>
  );
};

export default OpenRoute;
