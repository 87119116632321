import { observer } from 'mobx-react';
import React from 'react';
import InsightSummary from './InsightSummary';

const InsightSummaryContainer = ({ insightKey, summaries }) => summaries && (
<section className='insight-summaries'>
  {summaries.map((summary) => (
    <InsightSummary key={summary.summaryKey} insightKey={insightKey} summary={summary} />
  ))}
</section>
);

export default observer(InsightSummaryContainer);
